const version = {
	frontend: "0.0.5c - preproduction-uat beta",
	backend: "0.0.2a",
	database: "0.0.2a",
	database_env: "development",
	author: "Tijmen Baas",
	license: "Authorized for Stichting Vluchtelingenwerkgroep Wijk bij Duurstede",
	valid: "2024-04-30",
	test_completed: "2023-05-12",
	production: "2023-05-12",
};

export default version;
