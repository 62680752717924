import React, { useState, useEffect } from "react";
import Styles from "./Styles.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBadgeCheck, faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";

const styles = Styles;

function Zorg(props) {
	const persoon_id = props.id;
	const [data_huisarts, setData_huisarts] = useState([]);
	const [data_tandarts, setData_tandarts] = useState([]);
	const [data_apotheek, setData_apotheek] = useState([]);
	const [initiation, setInitiation] = useState(1);

	//huisarts
	const [praktijknaam, setPraktijknaam] = useState("");
	const [huisarts, setHuisarts] = useState("");
	const [huisarts_telefoon, setHuisarts_telefoon] = useState("");
	const [huisarts_email, setHuisarts_email] = useState("");
	const [huisarts_straat, setHuisarts_straat] = useState("");
	const [huisarts_huisnr, setHuisarts_huisnr] = useState("");
	const [huisarts_plaats, setHuisarts_plaats] = useState("");

	//tandarts
	const [tpraktijknaam, setTpraktijknaam] = useState("");
	const [tandarts, setTandarts] = useState("");
	const [tandarts_telefoon, setTandarts_telefoon] = useState("");
	const [tandarts_email, setTandarts_email] = useState("");
	const [tandarts_straat, setTandarts_straat] = useState("");
	const [tandarts_huisnr, setTandarts_huisnr] = useState("");
	const [tandarts_plaats, setTandarts_plaats] = useState("");

	//apotheek
	const [apotheek_naam, setApotheek_naam] = useState("");
	const [apotheek_telefoon, setApotheek_telefoon] = useState("");
	const [apotheek_email, setApotheek_email] = useState("");
	const [apotheek_straat, setApotheek_straat] = useState("");
	const [apotheek_huisnr, setApotheek_huisnr] = useState("");
	const [apotheek_plaats, setApotheek_plaats] = useState("");

	const savedata = {
		praktijknaam: praktijknaam,
		huisarts: huisarts,
		huisarts_telefoon: huisarts_telefoon,
		huisarts_email: huisarts_email,
		huisarts_straat: huisarts_straat,
		huisarts_huisnr: huisarts_huisnr,
		huisarts_plaats: huisarts_plaats,
		tpraktijknaam: tpraktijknaam,
		tandarts: tandarts,
		tandarts_telefoon: tandarts_telefoon,
		tandarts_email: tandarts_email,
		tandarts_straat: tandarts_straat,
		tandarts_huisnr: tandarts_huisnr,
		tandarts_plaats: tandarts_plaats,
		apotheek_naam: apotheek_naam,
		apotheek_telefoon: apotheek_telefoon,
		apotheek_email: apotheek_email,
		apotheek_straat: apotheek_straat,
		apotheek_huisnr: apotheek_huisnr,
		apotheek_plaats: apotheek_plaats,
	};

	const [render, setRender] = useState(0);
	const url_data = "/api/zorgverleners_data/" + persoon_id;
	const url_data_save = "/api/zorgverleners_data_save/" + persoon_id;
	const url_data_update = "/api/zorgverleners_data_update/" + persoon_id;

	useEffect(() => {
		axios({
			method: "GET",
			url: url_data,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setData_huisarts(res.data.huisartsdata);
				setData_tandarts(res.data.tandartsdata);
				setData_apotheek(res.data.apotheekdata);
				console.log(res.data);
				setInitiation(0);
			})
			.then(() => {
				setRender(true);
			})
			.catch((err) => {
				console.log(err);
				setInitiation(1);
			});
	}, []);

	useEffect(() => {
		setPraktijknaam(data_huisarts.praktijknaam);
		setHuisarts(data_huisarts.huisarts_naam);
		setHuisarts_telefoon(data_huisarts.huisarts_telefoon);
		setHuisarts_email(data_huisarts.huisarts_email);
		setHuisarts_straat(data_huisarts.huisarts_straat);
		setHuisarts_huisnr(data_huisarts.huisarts_huisnr);
		setHuisarts_plaats(data_huisarts.huisarts_plaats);
		setTpraktijknaam(data_tandarts.praktijknaam);
		setTandarts(data_tandarts.tandarts_naam);
		setTandarts_telefoon(data_tandarts.tandarts_telefoon);
		setTandarts_email(data_tandarts.tandarts_email);
		setTandarts_straat(data_tandarts.tandarts_straat);
		setTandarts_huisnr(data_tandarts.tandarts_huisnr);
		setTandarts_plaats(data_tandarts.tandarts_plaats);
		setApotheek_naam(data_apotheek.apotheek_naam);
		setApotheek_telefoon(data_apotheek.apotheek_telefoon);
		setApotheek_email(data_apotheek.apotheek_email);
		setApotheek_straat(data_apotheek.apotheek_straat);
		setApotheek_huisnr(data_apotheek.apotheek_huisnr);
		setApotheek_plaats(data_apotheek.apotheek_plaats);
	}, [render]);

	const handleSubmit = (e) => {
		if (initiation === 1) {
			e.preventDefault();
			axios({
				method: "POST",
				url: url_data_save,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res.status);
					setInitiation(0);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			e.preventDefault();
			axios({
				method: "PUT",
				url: url_data_update,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div>
			{" "}
			<button className={styles.button} onClick={handleSubmit}>
				<FontAwesomeIcon icon={faPaperPlane} /> Opslaan
			</button>
			<div className={styles.columnar}>
				<div>
					<p className={styles.columnhead}>Huisartszorg</p>

					<form className={styles.formmulti}>
						<label className={styles.label}>Naam huisartsenpraktijk</label>
						<input
							className={styles.input_care}
							type="text"
							id="praktijknaam"
							name="praktijknaam"
							value={praktijknaam}
							onChange={(e) => setPraktijknaam(e.target.value)}
						/>

						<label className={styles.label}>Naam huisarts</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_naam"
							name="huisarts_naam"
							value={huisarts}
							onChange={(e) => setHuisarts(e.target.value)}
						/>

						<label className={styles.label}>Telefoonnummer</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_telefoon"
							name="huisarts_telefoon"
							value={huisarts_telefoon}
							onChange={(e) => setHuisarts_telefoon(e.target.value)}
						/>

						<label className={styles.label}>Email-adres</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_email"
							name="huisarts_email"
							value={huisarts_email}
							onChange={(e) => setHuisarts_email(e.target.value)}
						/>

						<label className={styles.label}>Straatnaam</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_straat"
							name="huisarts_straat"
							value={huisarts_straat}
							onChange={(e) => setHuisarts_straat(e.target.value)}
						/>

						<label className={styles.label}>Huisnummer</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_huisnr"
							name="huisarts_huisnr"
							value={huisarts_huisnr}
							onChange={(e) => setHuisarts_huisnr(e.target.value)}
						/>

						<label className={styles.label}>Plaatsnaam</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_plaats"
							name="huisarts_plaats"
							value={huisarts_plaats}
							onChange={(e) => setHuisarts_plaats(e.target.value)}
						/>
					</form>
				</div>
				<div>
					<p className={styles.columnhead}>Tandartszorg</p>
					<form className={styles.formmulti}>
						<label className={styles.label}>Naam tandartsenpraktijk</label>
						<input
							className={styles.input_care}
							type="text"
							id="praktijknaam"
							name="praktijknaam"
							value={tpraktijknaam}
							onChange={(e) => setTpraktijknaam(e.target.value)}
						/>

						<label className={styles.label}>Naam tandarts</label>
						<input
							className={styles.input_care}
							type="text"
							id="tandarts_naam"
							name="tandarts_naam"
							value={tandarts}
							onChange={(e) => setTandarts(e.target.value)}
						/>

						<label className={styles.label}>Telefoonnummer</label>
						<input
							className={styles.input_care}
							type="text"
							id="tandarts_telefoon"
							name="tandarts_telefoon"
							value={tandarts_telefoon}
							onChange={(e) => setTandarts_telefoon(e.target.value)}
						/>

						<label className={styles.label}>Email-adres</label>
						<input
							className={styles.input_care}
							type="text"
							id="tandarts_email"
							name="tandarts_email"
							value={tandarts_email}
							onChange={(e) => setTandarts_email(e.target.value)}
						/>

						<label className={styles.label}>Straatnaam</label>
						<input
							className={styles.input_care}
							type="text"
							id="tandarts_straat"
							name="tandarts_straat"
							value={tandarts_straat}
							onChange={(e) => setTandarts_straat(e.target.value)}
						/>

						<label className={styles.label}>Huisnummer</label>
						<input
							className={styles.input_care}
							type="text"
							id="tandarts_huisnr"
							name="tandarts_huisnr"
							value={tandarts_huisnr}
							onChange={(e) => setTandarts_huisnr(e.target.value)}
						/>

						<label className={styles.label}>Plaatsnaam</label>
						<input
							className={styles.input_care}
							type="text"
							id="tandarts_plaats"
							name="tandarts_plaats"
							value={tandarts_plaats}
							onChange={(e) => setTandarts_plaats(e.target.value)}
						/>
					</form>
				</div>
				<div>
					<p className={styles.columnhead}>Apotheek</p>
					<form className={styles.formmulti}>
						<label className={styles.label}>Naam apotheek</label>
						<input
							className={styles.input_care}
							type="text"
							id="apotheek_naam"
							name="apotheek_naam"
							value={apotheek_naam}
							onChange={(e) => setApotheek_naam(e.target.value)}
						/>

						<label className={styles.label}>Telefoonnummer</label>
						<input
							className={styles.input_care}
							type="text"
							id="apotheek_telefoon"
							name="apotheek_telefoon"
							value={apotheek_telefoon}
							onChange={(e) => setApotheek_telefoon(e.target.value)}
						/>

						<label className={styles.label}>Email-adres</label>
						<input
							className={styles.input_care}
							type="text"
							id="apotheek_email"
							name="apotheek_email"
							value={apotheek_email}
							onChange={(e) => setApotheek_email(e.target.value)}
						/>

						<label className={styles.label}>Straatnaam</label>
						<input
							className={styles.input_care}
							type="text"
							id="apotheek_straat"
							name="apotheek_straat"
							value={apotheek_straat}
							onChange={(e) => setApotheek_straat(e.target.value)}
						/>

						<label className={styles.label}>Huisnummer</label>
						<input
							className={styles.input_care}
							type="text"
							id="apotheek_huisnr"
							name="apotheek_huisnr"
							value={apotheek_huisnr}
							onChange={(e) => setApotheek_huisnr(e.target.value)}
						/>

						<label className={styles.label}>Plaatsnaam</label>
						<input
							className={styles.input_care}
							type="text"
							id="apotheek_plaats"
							name="apotheek_plaats"
							value={apotheek_plaats}
							onChange={(e) => setApotheek_plaats(e.target.value)}
						/>
					</form>
				</div>
				{/* <div>
					<p className={styles.columnhead}>Verloskundigen</p>
					<form className={styles.formmulti}>
						<label className={styles.label}>Naam Verloskundigenpraktijk</label>
						<input
							className={styles.input_care}
							type="text"
							id="praktijknaam"
							name="praktijknaam"
							value={praktijknaam}
							onChange={(e) => setPraktijknaam(e.target.value)}
						/>

						<label className={styles.label}>Naam huisarts</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_naam"
							name="huisarts_naam"
							value={huisarts}
							onChange={(e) => setHuisarts(e.target.value)}
						/>

						<label className={styles.label}>Telefoonnummer</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_telefoon"
							name="huisarts_telefoon"
							value={huisarts_telefoon}
							onChange={(e) => setHuisarts_telefoon(e.target.value)}
						/>

						<label className={styles.label}>Email-adres</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_email"
							name="huisarts_email"
							value={huisarts_email}
							onChange={(e) => setHuisarts_email(e.target.value)}
						/>

						<label className={styles.label}>Straatnaam</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_straat"
							name="huisarts_straat"
							value={huisarts_straat}
							onChange={(e) => setHuisarts_straat(e.target.value)}
						/>

						<label className={styles.label}>Huisnummer</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_huisnr"
							name="huisarts_huisnr"
							value={huisarts_huisnr}
							onChange={(e) => setHuisarts_huisnr(e.target.value)}
						/>

						<label className={styles.label}>Plaatsnaam</label>
						<input
							className={styles.input_care}
							type="text"
							id="huisarts_plaats"
							name="huisarts_plaats"
							value={huisarts_plaats}
							onChange={(e) => setHuisarts_plaats(e.target.value)}
						/>
					</form>
				</div> */}
			</div>{" "}
			<button className={styles.button} onClick={handleSubmit}>
				<FontAwesomeIcon icon={faPaperPlane} /> Opslaan
			</button>
		</div>
	);
}

export default Zorg;
