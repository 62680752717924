import React, { useState, useEffect } from "react";
import Styles from "./Styles.jsx";
import axios from "axios";

const styles = Styles;

function Voorblad(props) {
	const token = props.token;
	const person_id = props.id;
	const mbLijst = props.begeleider;
	const [begeleiders, setBegeleiders] = useState([]);
	const [initiation, setInitiation] = useState(1);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [render, setRender] = useState(false);
	//const [mbLijst, setMbLijst] = useState([]);
	const [begeleider, setBegeleider] = useState("1");
	const [startdatumBegeleiding, setStartdatumBegeleiding] = useState("");
	const [einddatumBegeleiding, setEinddatumBegeleiding] = useState("");
	const [klantNummerRSD, setKlantNummerRSD] = useState("");
	const [teamNummerRSD, setTeamNummerRSD] = useState("");
	const [inkomensconsulent, setInkomensconsulent] = useState("");
	const [taalaanbieder, setTaalaanbieder] = useState("");
	const [einddatumInburgering, setEinddatumInburgering] = useState("");
	const urlvoorblad = "/api/voorblad/" + person_id;
	const urlvoorblad_save = "/api/voorblad_save/" + person_id;
	const urlvoorblad_update = "/api/voorblad_update/" + person_id;

	const savedata = {
		id: person_id,
		begeleider: begeleider,
		startdatumBegeleiding: startdatumBegeleiding,
		einddatumBegeleiding: einddatumBegeleiding,
		klantNummerRSD: klantNummerRSD,
		teamNummerRSD: teamNummerRSD,
		inkomensconsulent: inkomensconsulent,
		taalaanbieder: taalaanbieder,
		einddatumInburgering: einddatumInburgering,
	};

	useEffect(() => {
		if (mbLijst) {
			setBegeleiders(mbLijst);
			console.log(mbLijst);
		} else {
			setBegeleiders([]);
		}
	}, []);

	useEffect(() => {
		axios({
			method: "get",
			url: urlvoorblad,
			headers: { Authorization: "Bearer " + token },
		})
			.then((res) => {
				setData(res.data);
				console.log(res.data);
				setInitiation(0);
				setIsLoading(false);
			})
			.then((res) => setRender(true))
			.catch((err) => {
				console.log(err);
				setInitiation(1);
			});
	}, []);

	useEffect(() => {
		setBegeleider(data.maats_begeleider);
		setStartdatumBegeleiding(data.start_datum_begeleiding);
		setEinddatumBegeleiding(data.eind_datum_begeleiding);
		setKlantNummerRSD(data.klant_nr_rsd);
		setTeamNummerRSD(data.team_nr_rsd);
		setInkomensconsulent(data.inkomensconsulent);
		setTaalaanbieder(data.taalaanbieder);
		setEinddatumInburgering(data.einddat_inburgering);
	}, [render]);

	const handleClick = (e) => {
		if (initiation === 1) {
			e.preventDefault();

			axios({
				method: "post",
				url: urlvoorblad_save,
				data: savedata,
				headers: { Authorization: "Bearer " + token },
			})
				.then((res) => {
					setInitiation(0);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			e.preventDefault();
			console.log("you hit the update functions");
			axios({
				method: "put",
				url: urlvoorblad_update,
				headers: { Authorization: "Bearer " + token },
				data: savedata,
			})
				.then((res) => {})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div className={styles.container}>
			<div>
				<div className={styles.offset}>
					<form className={styles.form} onSubmit={handleClick}>
						<label className={styles.label}>Begeleider</label>
						<select
							className={styles.checkselect}
							name="maatschappelijkbegeleider"
							id="maatschappelijkbegeleider"
							onChange={(e) => setBegeleider(e.target.value)}
							value={begeleider}
							required="required"
						>
							{begeleiders.map((mb) => (
								<option value={mb.id}>
									{mb.userfirst} {mb.userlast}
								</option>
							))}
						</select>

						<label className={styles.label}> Start datum begeleiding</label>

						<input
							className={styles.input}
							type="date"
							value={startdatumBegeleiding}
							name="startdatumBegeleiding"
							id="startdatumBegeleiding"
							required="required"
							onChange={(e) => setStartdatumBegeleiding(e.target.value)}
						/>
						<label className={styles.label}> Eind datum begeleiding</label>
						<input
							className={styles.input}
							value={einddatumBegeleiding}
							type="date"
							name="einddatumBegeleiding"
							id="einddatumBegeleiding"
							required="required"
							onChange={(e) => setEinddatumBegeleiding(e.target.value)}
						/>
						<label className={styles.label}>Klantnummer RSD</label>
						<input
							className={styles.input}
							type="text"
							value={klantNummerRSD}
							placeholder="Klant nummer RSD"
							onChange={(e) => setKlantNummerRSD(e.target.value)}
						/>
						<label className={styles.label}>Team nummer RSD</label>
						<input
							className={styles.input}
							value={teamNummerRSD}
							type="text"
							placeholder="Team nummer RSD"
							onChange={(e) => setTeamNummerRSD(e.target.value)}
						/>
						<label className={styles.label}>Naam inkomensconsulent</label>
						<input
							className={styles.input}
							value={inkomensconsulent}
							type="text"
							placeholder="Inkomensconsulent"
							onChange={(e) => setInkomensconsulent(e.target.value)}
						/>
						<label className={styles.label}>Taalaanbieder</label>
						<input
							className={styles.input}
							value={taalaanbieder}
							type="text"
							placeholder="Taalaanbieder"
							onChange={(e) => setTaalaanbieder(e.target.value)}
						/>
						<label className={styles.label}>Eindatum inburgering</label>
						<input
							className={styles.input}
							value={einddatumInburgering}
							onChange={(e) => setEinddatumInburgering(e.target.value)}
							type="date"
							required="required"
						/>

						<button className={styles.button}>Gegevens opslaan</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Voorblad;
