import { faList, faPeopleGroup } from "@fortawesome/pro-solid-svg-icons";
import {
	faComments,
	faTentArrowDownToLine,
} from "@fortawesome/pro-duotone-svg-icons";

const FamilyButtons = {
	cardButtons: [
		{
			id: 1,
			icon: faList,
			title: "Update stamgegevens familie",
			link: "/familyupdate",
			category: "In use",
		},
		{
			id: 2,
			icon: faPeopleGroup,
			title: "Familielid toevoegen",
			link: "/clientcreate",
			category: "In use",
		},
		{
			id: 3,
			icon: faComments,
			title: "Aantekeningen familie",
			link: "/familynotes",
			category: "In use",
		},
		{
			id: 4,
			icon: faTentArrowDownToLine,
			title: "Familie archief",
			link: "/familyfunction",
			category: "Not used",
		},
	],
};

export default FamilyButtons;
