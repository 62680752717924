import React, { useState, useEffect } from "react";
import Styles from "./Styles.jsx";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";

const styles = Styles;

function Formulieren(props) {
	const id = props.id;
	const [initiation, setInitiation] = useState(1);
	const [data, setData] = useState([]);
	const [bet_voorschot_lening_kbnl, setBet_voorschot_lening_kbnl] =
		useState(false);
	const [bet_voorschot_lening_kbnl_date, setBet_voorschot_lening_kbnl_date] =
		useState("1900-01-01");
	const [bet_overname_inboedel, setBet_overname_inboedel] = useState(false);
	const [bet_overname_inboedel_date, setBet_overname_inboedel_date] =
		useState("1900-01-01");
	const [opvraag_jur_dossier, setOpvraag_jur_dossier] = useState(false);
	const [opvraag_jur_dossier_date, setOpvraag_jur_dossier_date] =
		useState("1900-01-01");
	const savedata = {
		bet_voorschot_lening_kbnl: bet_voorschot_lening_kbnl,
		bet_voorschot_lening_kbnl_date: bet_voorschot_lening_kbnl_date,
		bet_overname_inboedel: bet_overname_inboedel,
		bet_overname_inboedel_date: bet_overname_inboedel_date,
		opvraag_jur_dossier: opvraag_jur_dossier,
		opvraag_jur_dossier_date: opvraag_jur_dossier_date,
		persoon_id: id,
	};
	const [render, setRender] = useState(false);
	const urlformulieren = "/api/formulieren/" + id;
	const urlformulierenupdt = "/api/formulierenupdate/" + id;
	const urlformulierensave = "/api/formulierensave/" + id;

	useEffect(() => {
		axios({
			method: "GET",
			url: urlformulieren,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res.status);
				setData(res.data);
				setInitiation(0);
			})
			.then((res) => {
				setRender(true);
			})
			.catch((err) => {
				console.log(err);
				setInitiation(1);
			});
	}, []);

	useEffect(() => {
		setBet_overname_inboedel(data.bet_overname_inboedel);
		setBet_overname_inboedel_date(data.bet_overname_inboedel_date);
		setBet_voorschot_lening_kbnl(data.bet_voorschot_lening_kbnl);
		setBet_voorschot_lening_kbnl_date(data.bet_voorschot_lening_kbnl_date);
		setOpvraag_jur_dossier(data.opvraag_jur_dossier);
		setOpvraag_jur_dossier_date(data.opvraag_jur_dossier_date);
	}, [render]);

	const handleSubmit = (e) => {
		if (initiation === 1) {
			e.preventDefault();
			axios({
				method: "POST",
				url: urlformulierensave,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res.status);
					setInitiation(0);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			e.preventDefault();
			axios({
				method: "PUT",
				url: urlformulierenupdt,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div>
			<button className={styles.button} onClick={handleSubmit}>
				<FontAwesomeIcon icon={faPaperPlane} /> Opslaan
			</button>
			<div>
				<div>
					<form className={styles.checklist}>
						<label className={styles.label}>Voorschot lening KBNL</label>
						<select
							className={
								bet_voorschot_lening_kbnl === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="bet_voorschot_lening_kbnl"
							name="bet_voorschot_lening_kbnl"
							value={bet_voorschot_lening_kbnl}
							onChange={(e) => {
								bet_voorschot_lening_kbnl === true
									? setBet_voorschot_lening_kbnl(false)
									: setBet_voorschot_lening_kbnl(true);
								setBet_voorschot_lening_kbnl_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="bet_voorschot_lening_kbnl_date"
							name="bet_voorschot_lening_kbnl_date"
							value={bet_voorschot_lening_kbnl_date}
							onChange={(e) =>
								setBet_voorschot_lening_kbnl_date(e.target.value)
							}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Betaling overname boedel</label>
						<select
							className={
								bet_overname_inboedel === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="bet_overname_inboedel"
							name="bet_overname_inboedel"
							value={bet_overname_inboedel}
							onChange={(e) => {
								bet_overname_inboedel === true
									? setBet_overname_inboedel(false)
									: setBet_overname_inboedel(true);
								setBet_overname_inboedel_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="bet_overname_inboedel_date"
							name="bet_overname_inboedel_date"
							value={bet_overname_inboedel_date}
							onChange={(e) => setBet_overname_inboedel_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Opvragen juridisch dossier</label>
						<select
							className={
								opvraag_jur_dossier === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="opvraag_jur_dossier"
							name="opvraag_jur_dossier"
							value={opvraag_jur_dossier}
							onChange={(e) => {
								opvraag_jur_dossier === true
									? setOpvraag_jur_dossier(false)
									: setOpvraag_jur_dossier(true);
								setOpvraag_jur_dossier_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="opvraag_jur_dossier_date"
							name="opvraag_jur_dossier_date"
							value={opvraag_jur_dossier_date}
							onChange={(e) => setOpvraag_jur_dossier_date(e.target.value)}
							className={styles.checkdate}
						/>
					</form>
					<button className={styles.button} onClick={handleSubmit}>
						<FontAwesomeIcon icon={faPaperPlane} /> Opslaan
					</button>
				</div>
			</div>
		</div>
	);
}

export default Formulieren;
