import React, { useState, useEffect } from "react";
import axios from "axios";

function Mijn_clienten(props) {
  const [clienten, setClienten] = useState({});

  useEffect(() => {
    axios({
      method: "GET",
      url: "/api/mijnclienten",
      headers: { Authorization: "Bearer " + props.token },
    })
      .then((res) => {
        setClienten(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(clienten);

  return (
    <div>
      Mijn_clienten
      <div></div>
    </div>
  );
}

export default Mijn_clienten;
