import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrakeWarning } from "@fortawesome/pro-duotone-svg-icons";

function Family_update(props) {
	const styles = {
		submitbutton:
			"border bg-cyan-600 hover:bg-cyan-700 w-40 p-4 my-2 text-white rounded-lg",
		container: "h-screen p-10 w-1/2",
		form: "flex flex-col py-2",
		label: "text-[10pt] text-left py-2 font-medium text-cyan-700",
		input:
			"text-s font-serif text-cyan-900 border-2 border-b-cyan-800 p-1 border-t-0 border-l-0 border-r-0 focus:outline-none",
		header: "text-xl font-bold text-cyan-700",
		table: "min-w-full leading-normal mt-5",
		tableheader:
			"px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-l font-semibold text-gray-600 uppercase tracking-wider",
		tablelines: "border border-green-600 mt-5",
		tableitem: "border border-green-600 mt-5 text-left",
		warning: "text-red-600 text-sm font-bold",
	};
	const navigate = useNavigate();
	const location = useLocation();
	const { from } = location.state;
	const [familienaam, setFamilienaam] = useState("");
	const [straat, setStraat] = useState("");
	const [huisnummer, setHuisnummer] = useState("");
	const [huistnummertoev, setHuisnummertoev] = useState("");
	const [postcode, setPostcode] = useState("");
	const [plaats, setPlaats] = useState("");
	const [mbdata, setMbdata] = useState([]);
	const [begeleider, setBegeleider] = useState("1");
	const [familiedata, setFamiliedata] = useState([]);
	const [begeleiderdata, setBegeleiderdata] = useState([]);
	const [render, setRender] = useState(false);
	console.log(from.id);
	const senddata = {
		familienaam: familienaam,
		straat: straat,
		huisnummer: huisnummer,
		huistnummertoev: huistnummertoev,
		postcode: postcode,
		plaats: plaats,
		mb_id: begeleider,
	};

	const url = "/api/familycard/" + from.id;

	useEffect(() => {
		axios({
			method: "GET",
			url: url,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res.data);
				setFamiliedata(res.data.Familyinfo);
				setBegeleiderdata(res.data.MB);
				setRender(1);
			})
			.catch((err) => {
				console.log(err);
				console.log(from.id);
			});
	}, []);

	useEffect(() => {
		setFamilienaam(familiedata.familienaam);
		setStraat(familiedata.straat);
		setHuisnummer(familiedata.huisnummer);
		setHuisnummertoev(familiedata.huisnummer_toev);
		setPostcode(familiedata.postcode);
		setPlaats(familiedata.woonplaats);
		setBegeleider(begeleiderdata.mbnameid);
	}, [render]);

	useEffect(() => {
		axios({
			method: "GET",
			url: "/api/mblijst",
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setMbdata(res.data);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		axios({
			method: "PUT",
			url: "/api/updatefamily/" + from.id,
			data: senddata,
			withCredentials: true,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log(err.res);
				console.log(err.response.status);
				console.log(err.response.headers);
			});
		navigate("/overview");
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				Updaten familieinformatie
				<br />
				{/* <p className={styles.warning}>
					<FontAwesomeIcon icon={faBrakeWarning} bounce size="xl" /> N.B. deze
					functie is nog niet gekoppeld en werkt dus nog niet!!!!{" "}
					<FontAwesomeIcon icon={faBrakeWarning} bounce size="xl" />
				</p> */}
			</div>
			<form className={styles.form} onSubmit={handleSubmit}>
				<label className={styles.label} htmlFor="familienaam">
					Familienaam
				</label>
				<input
					className={styles.input}
					type="text"
					name="familienaam"
					id="familienaam"
					required="required"
					pattern="^[A-Za-z0-9]{3,16}$"
					value={familienaam}
					onChange={(e) => setFamilienaam(e.target.value)}
				/>
				<label className={styles.label} htmlFor="straatnaam">
					straatnaam
				</label>
				<input
					className={styles.input}
					type="text"
					name="straatnaam"
					id="straatnaam"
					required="required"
					value={straat}
					onChange={(e) => setStraat(e.target.value)}
				/>
				<label className={styles.label} htmlFor="huisnummer">
					huisnummer
				</label>
				<input
					className={styles.input}
					type="number"
					name="huisnummer"
					required="required"
					pattern="^[0-9]{1,5}$"
					id="huisnummer"
					value={huisnummer}
					onChange={(e) => setHuisnummer(e.target.value)}
				/>
				<label className={styles.label} htmlFor="huisnummer_toev">
					huisnummer_toev
				</label>
				<input
					className={styles.input}
					type="text"
					name="huisnummer_toev"
					id="huisnummer_toev"
					value={huistnummertoev}
					onChange={(e) => setHuisnummertoev(e.target.value)}
				/>
				<label className={styles.label} htmlFor="postcode">
					postcode
				</label>
				<input
					className={styles.input}
					type="text"
					name="postcode"
					id="postcode"
					required="required"
					pattern="^[0-9]{4}[A-Za-z]{2}$"
					value={postcode}
					onChange={(e) => setPostcode(e.target.value)}
				/>
				<label className={styles.label} htmlFor="woonplaats">
					woonplaats
				</label>
				<input
					className={styles.input}
					type="text"
					name="woonplaats"
					id="woonplaats"
					required="required"
					value={plaats}
					onChange={(e) => setPlaats(e.target.value)}
				/>

				<label className={styles.label} htmlFor="mb-er">
					Maatschappelijke begeleider
				</label>
				<select
					className={styles.checkselect}
					name="maatschappelijkbegeleider"
					id="maatschappelijkbegeleider"
					value={begeleider}
					onChange={(e) => setBegeleider(e.target.value)}
				>
					{mbdata.map((mb) => (
						<option value={mb.id}>
							{mb.userfirst} {mb.userlast}
						</option>
					))}
				</select>
				<br />
				<input
					className={styles.submitbutton}
					type="submit"
					value="Submit"
					disabled={false}
				/>
			</form>
		</div>
	);
}

export default Family_update;
