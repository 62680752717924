import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";

function Family_notes(props) {
  const location = useLocation();
  const { from } = location.state;
  const family_id = from.id;
  const navigate = useNavigate();
  const [notes, setNotes] = useState("empty");
  const [data, setData] = useState([]);
  const [deleted, setDeleted] = useState("");
  const [added, setAdded] = useState(0);

  const url = "/api/family_notes/" + family_id;
  const url_save = "/api/family_notes_save/" + family_id;
  const url_delete = "/api/family_notes_delete/";

  const savedata = {
    id: family_id,
    notitie: notes,
  };

  useEffect(() => {
    axios({
      method: "get",
      url: url,
      headers: { Authorization: "Bearer " + props.token },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [deleted, added]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: url_save,
      data: savedata,
      headers: { Authorization: "Bearer " + props.token },
    })
      .then((res) => {
        setAdded(added + 1);
      })
      .then(() => {
        setNotes("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (deldata) => {
    axios({
      method: "delete",
      url: url_delete + deldata,
      data: deldata,
      headers: { Authorization: "Bearer " + props.token },
    })
      .then((res) => {
        if (res) {
          setDeleted(deldata);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.container}>
      <div>
        <p>Notitie toevoegen voor familie</p>
        <div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <label className={styles.label1}>Notitie</label>
            <textarea
              className={styles.input1}
              name="notitie"
              id="notitie"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <button className={styles.iconbutton}>Notitie toevoegen</button>
          </form>
        </div>
        <div>
          <div className={styles.divider}>Notities reeds aangemaakt</div>
          <div className={styles.tablecontainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.tableheader}>Datum</th>
                  <th className={styles.tableheader}>Notitie</th>
                  <th className={styles.tableheader}>Acties</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td className={styles.tableitem}>{item.date}</td>
                    <td className={styles.tableitem}>{item.note}</td>
                    <td className={styles.tableitem}>
                      <button
                        className={styles.iconbutton}
                        onClick={() => handleDelete(item.id)}
                      >
                        Verwijderen
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Family_notes;

const styles = {
  container: "container h-screen w-[90%] overflow-auto",
  divider:
    "border-b-2 border-t-2 border-t-cyan-800 border-b-cyan-800 font-serif ml-5 mt-5",
  table: "min-w-full leading-normal mt-5",
  tableheader:
    "px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-l font-semibold text-gray-600 uppercase tracking-wider",
  tablelines: "border-t-green-600 border-b-green-600 mt-5",
  tableitem:
    "border-t-2 border-b-2 border-t-green-600 border-b-green-600 py-2 text-left",
  tablecontainer: "ml-5",
  form: "ml-5 flex flex-wrap py-2 mx-auto bg-gray-100 rounded-lg",
  checklist:
    "grid grid-cols-3 row-auto mt-2 ml-5 py-2 mx-auto bg-gray-100 rounded-lg w-[97%]",
  checklistmulti:
    "grid grid-cols-3 row-auto mt-2 ml-1 py-2 mx-auto bg-gray-100 rounded-lg w-[97%]",
  checkselectcompl: "ml-5 w-[35%] mr-5 py-2 mx-auto bg-green-200 text-[10pt]",
  checkselectfirst: "ml-5 w-[35%] mr-5 py-2 mx-auto bg-yellow-200 text-[10pt]",
  checkdate: "ml-5 w-[70%] mr-5 py-2 mx-auto bg-gray-100 text-[10pt]",
  form2col: "grid grid-cols-4 flex flex-wrap flex-col py-2 mx-auto",
  label:
    "m-1 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[50%]",
  label1:
    "inline flex-wrap m-2 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[50%]",
  checkbox:
    "m-2 w-5 h-5 text-cyan-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500",
  button: "m-2 p-2 bg-cyan-600 text-white",
  input:
    "p-1 m-1 font-serif text-l text-emerald-800 font-medium border-b-teal-700 border-t-teal-700 bg-teal-500 border-solid border-2 border-t-2 border-l-0 border-r-0 mr-10 w-96 outline-0 required:border-red-500 bg-gray-100",
  input1:
    "inline flex-wrap p-1 m-2 font-serif text-l text-emerald-800 font-medium border-b-teal-700 border-t-b-teal-700 border-solid border-2 border-t-1 bg-teal-200 border-l-0 border-r-0 mr-10 w-[50%] outline-0 bg-gray-100 required:border-red-500",
  column: "flex flex-col",
  columnar: "grid grid-cols-3 gap-4",
  columnhead: "text-l font-bold text-gray-500",
  iconbutton: "text-l bg-cyan-600 text-white content-center p-2 font-bold",
};
