import React from "react";

function Profile() {
	const check_token = localStorage.getItem("token");

	return (
		<div>
			Profile
			<div>{check_token}</div>
		</div>
	);
}

export default Profile;
