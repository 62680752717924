import { faList, faPeopleGroup } from "@fortawesome/pro-solid-svg-icons";
import {
	faCubes,
	faBrowsers,
	faBug,
	faBrainCircuit,
	faUsersViewfinder,
	faPeopleArrows,
	faCodeMerge,
} from "@fortawesome/pro-duotone-svg-icons";

const MenuItems = {
	clienten: [
		{
			id: 1,
			icon: faList,
			title: "Overzicht families",
			link: "/overview",
			category: "In use",
		},
		{
			id: 2,
			icon: faPeopleGroup,
			title: "Voeg familie toe",
			link: "/familycreate",
			category: "In use",
		},
		{
			id: 3,
			icon: faPeopleGroup,
			title: "Exporteer alle families",
			link: "/familycreate",
			category: "Not used",
		},
		{
			id: 4,
			icon: faUsersViewfinder,
			title: "Overzicht personen",
			link: "/clientlist",
			category: "In use",
		},
	],
	begeleiders: [
		{
			id: 1,
			icon: faPeopleArrows,
			title: "Spoorboekje",
			link: "/spoorboekje",
			category: "Not used",
		},
		{
			id: 2,
			icon: faCubes,
			title: "Mijn cliënten",
			link: "/mijn_clienten",
			category: "Not used",
		},
		{
			id: 3,
			icon: faBrowsers,
			title: "Kladblok",
			link: "/overview",
			category: "Not used",
		},
	],
	applicatie: [
		{
			id: 1,
			icon: faBug,
			title: "Meld een bug",
			link: "/overview",
			category: "Not used",
		},
		{
			id: 2,
			icon: faBrainCircuit,
			title: "Documentatie",
			link: "/overview",
			category: "Not used",
		},
		{
			id: 3,
			icon: faCodeMerge,
			title: "Versieinformatie",
			link: "/versie",
			category: "In use",
		},
	],
};
export default MenuItems;
