import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "./Navigation";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";

function Spoorboekje(props) {
	const location = useLocation();
	const { from } = location.state;
	const persoon_id = from.id;
	console.log(persoon_id);

	const [isloading, setIsLoading] = useState(true);
	const [persoon, setPersoon] = useState([]);
	const [mbLijst, setMbLijst] = useState([]);

	function sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	}

	useEffect(() => {
		axios({
			method: "get",
			url: "/api/clientdetail/" + persoon_id,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setPersoon(res.data);
				sleep(1000);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		axios({
			method: "get",
			url: "/api/mblijst",
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setMbLijst(res.data);
				sleep(500);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div className={styles.container}>
			{isloading ? (
				<div>
					<PulseLoader color={"#7fe37e"} loading={isloading} size={70} />
				</div>
			) : (
				<div>
					<div className={styles.header}>Spoorboekje versie 1</div>
					<div>Detailinformatie client</div>
					<br />
					<div className={styles.columnar}>
						<div>
							<div className={styles.label}>Naam</div>
							<div className={styles.data}>
								{persoon.voornaam} {persoon.middelnaam} {persoon.achternaam}
							</div>
						</div>
						<div>
							<div className={styles.label}>E-mailadres</div>
							<div className={styles.data}>{persoon.emailadres}</div>
						</div>
						<div>
							<div className={styles.label}>Achternaam</div>
							<div className={styles.data}>{persoon.achternaam}</div>
						</div>
					</div>
					<div>
						<Navigation
							id={persoon.id}
							token={props.token}
							setToken={props.setToken}
							mb={mbLijst}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default Spoorboekje;

const styles = {
	container: "flex flex-col w-screen h-screen p-6",
	header: "text-2xl font-bold text-green-600",
	label: "ml-5 text-[10pt] text-left font-medium text-black",
	data: "ml-5 text-[12pt] font-serif text-left text-black mb-2",
	columnar: "grid grid-cols-3 gap-4",
};
