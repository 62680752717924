import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import FamilyButtons from "../Components/FamilyButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShuttleSpace } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Family_card(props) {
	const styles = {
		left_block: "ml-5 h-76 bg-cyan-600 justify-left text-left",
		grid: "grid grid-cols-2 grid-rows-1 mt-5",
		header: "ml-5 text-2xl font-bold text-white",
		label: "ml-5 text-[10pt] text-left font-medium text-black",
		detail: "font-serif text-gray-700 hover:text-red-800 whitespace-no-wrap",
		data: "ml-5 text-[14pt] font-serif text-left text-white mb-2",
		tiles: "ml-20 grid grid-cols-2 grid-rows-2 gap-2",
		tilesblock: "p-2 bg-white w-[70%] bg-cyan-100 rounded-lg shadow-lg",
		tileicon: "p-2 text-cyan-800 text-3xl mb-2 mt-2",
		divider:
			"border-b-2 border-t-2 border-t-cyan-800 border-b-cyan-800 font-serif ml-5",
		table: "min-w-full leading-normal mt-5",
		tableheader:
			"px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-l font-semibold text-gray-600 uppercase tracking-wider",
		tablelines: "border-t-green-600 border-b-green-600 mt-5",
		tableitem:
			"border-t-2 border-b-2 border-t-green-600 border-b-green-600 py-2 text-left",
		tablecontainer: "ml-5",
		container: "container h-screen w-[90%] overflow-auto",
	};
	const location = useLocation();
	const { from } = location.state;
	const id = from.id;
	const url = "/api/familycard/" + id.toString();
	const [family, setFamily] = useState([]);
	const [members, setMembers] = useState([]);
	const buttons = FamilyButtons.cardButtons.filter(
		(item) => item.category !== "Not used"
	);

	//refresh family informatie
	useEffect(() => {
		axios({
			method: "GET",
			url: url,
			headers: { Authorization: "Bearer " + props.token },
		}).then((res) => {
			setFamily(res.data.Familyinfo);
		});
	}, []);

	//get family members from api
	useEffect(() => {
		axios({
			method: "GET",
			url: "/api/familymembers/" + id.toString(),
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setMembers(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	function getIcon(icon) {
		const toImport = icon;
		return <FontAwesomeIcon icon={toImport} />;
	}
	const navigate = useNavigate();
	const handleDoubleClick = (item) => {
		navigate("/spoorboekje/", { state: { from: item } });
	};

	return (
		<div className={styles.container}>
			<div className={styles.grid}>
				<div className={styles.left_block}>
					<p className={styles.header}>
						Familiekaart voor familie <i>{family.familienaam}</i>
					</p>
					<br></br>
					<div className={styles.label}>Adres</div>
					<div className={styles.data}>
						{family.straat} {family.huisnummer} {family.huisnummer_toev}
					</div>
					<div className={styles.label}>Postcode</div>
					<div className={styles.data}>{family.postcode}</div>
					<div className={styles.label}>Woonplaats</div>
					<div className={styles.data}>{family.woonplaats}</div>
				</div>
				<div className={styles.tiles}>
					{buttons.map((item) => (
						<div className={styles.tilesblock} key={item.id}>
							<Link to={item.link} state={{ from: family }}>
								<div className={styles.tileicon}>{getIcon(item.icon)}</div>
								<div className={styles.tiletext}>
									{item.title} voor {from.familienaam}
								</div>
							</Link>
						</div>
					))}
				</div>
			</div>
			<br />
			<div className={styles.divider}>Familieleden</div>
			<br />
			<div className={styles.tablecontainer}>
				<table className={styles.table}>
					<thead>
						<tr>
							<th className={styles.tableheader}>Voornaam</th>
							<th className={styles.tableheader}>Achternaam</th>
							<th className={styles.tableheader}>E-mailadres</th>
							<th className={styles.tableheader}>Telefoonnummer</th>
							<th className={styles.tableheader}>Familiehoofd</th>
						</tr>
					</thead>
					<tbody>
						{members.map((item) => (
							<tr key={item.id} className={styles.tablelines}>
								<td className={styles.tableitem}>
									<p
										className={styles.detail}
										onDoubleClick={() => handleDoubleClick(item)}
									>
										{item.voornaam}
									</p>
								</td>
								<td className={styles.tableitem}>
									<p
										className={styles.detail}
										onDoubleClick={() => handleDoubleClick(item)}
									>
										{item.achternaam}
									</p>
								</td>
								<td className={styles.tableitem}>
									{" "}
									<p
										className={styles.detail}
										onDoubleClick={() => handleDoubleClick(item)}
									>
										{item.emailadres}
									</p>
								</td>
								<td className={styles.tableitem}>
									{" "}
									<p
										className={styles.detail}
										onDoubleClick={() => handleDoubleClick(item)}
									>
										{item.mobiel}
									</p>
								</td>
								<td className={styles.tableitem}>
									{item.familie_hoofd === true ? "Ja" : "Nee"}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default Family_card;
