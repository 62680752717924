import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FlashMessage from "react-flash-message";

const LockView = require("../Assets/Pictures/roman-ruins-of-merida-tunnel-or-access-arch-to-th-2022-09-13-22-58-22-utc.jpg");

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const styles = {
    container: "flex",
    sideimage: "basis-1/3 justify-center h-screen",
    image: "w-full h-full object-cover",
    form: "p-10 mt-[10%] w-max-[70%] flex flex-col py-2 basis-2/3 mx-auto",
    label: "py-2 font-medium text-cyan-700",
    input: "border p-3 rounded",
    submitbutton:
      "border bg-cyan-600 hover:bg-cyan-700 w-full p-4 my-2 text-white rounded-lg",
    flash: "bg-red-500 text-white p-2 rounded",
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: "/api/loginreact",
      data: {
        username: username,
        password: password,
        token: otp,
      },
    })
      .then((res) => {
        //localStorage.setItem("token", res.data.access_token);
        props.setToken(res.data.access_token);
        props.setUser(res.data.current_user);
      })
      .catch((err) => {
        console.log(err);
        // if (err.response.status === 401) {
        //   console.log("401");
        //   setStatus("401");
        // }
      });
    navigate("/overview");
    setUsername("");
    setPassword("");
    setOtp("");
    setStatus("");
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        {status === "401" ? (
          <FlashMessage duration={2500}>
            <p className={styles.flash}>
              Incorrect username, password or token
            </p>
          </FlashMessage>
        ) : null}
        <label className={styles.label} id="username">
          Username
        </label>
        <input
          className={styles.input}
          type="text"
          value={username}
          name="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <label className={styles.label} id="password">
          Password
        </label>
        <input
          className={styles.input}
          type="password"
          value={password}
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <label className={styles.label} id="token">
          Token
        </label>
        <input
          className={styles.input}
          type="text"
          value={otp}
          name="otp"
          onChange={(e) => setOtp(e.target.value)}
        />
        <input className={styles.submitbutton} type="submit" value="Submit" />
      </form>
      <div className={styles.sideimage}>
        <img className={styles.image} src={LockView} />
      </div>
    </div>
  );
}

export default Login;
