import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import version from "../Utilities/version";

const styles = {
	sidemenu: "bg-cyan-700 p-5 pt-8 w-72 relative",
	menu: "text-white text-l",
	menu2: "text-red-600 bg-yellow-500 font-bold text-l",
	submenu: "text-white uppercase text-[12pt] font-bold",
	subitem: "text-white text-[12pt]",
	divider: "border-b border-white my-2",
	thanks: "text-white text-[8pt]",
	ul: "mt-2",
	li: "my-2 hover:text-cyan-400 ml-2",
};

function Sidemenu() {
	const menuItemsClienten = MenuItems.clienten.filter(
		(item) => item.category !== "Not used"
	);
	const menuItemsBegeleider = MenuItems.begeleiders.filter(
		(item) => item.category !== "Not used"
	);
	const menuItemsApplicatie = MenuItems.applicatie.filter(
		(item) => item.category !== "Not used"
	);

	function getIcon(icon) {
		const toImport = icon;
		return <FontAwesomeIcon icon={toImport} />;
	}

	return (
		<div className={styles.sidemenu}>
			<div className={styles.menu}>Menu</div>

			<div className={styles.divider}></div>
			<div className={styles.subitem}>
				<div className={styles.submenu}>Clienten</div>
				<ul>
					{menuItemsClienten.map((item) => (
						<li className={styles.li}>
							<Link to={item.link}>
								{getIcon(item.icon)} {item.title}
							</Link>
						</li>
					))}
				</ul>
			</div>
			<div className={styles.divider}></div>
			<div className={styles.subitem}>
				<div className={styles.submenu}>Begeleider</div>
				<ul>
					{menuItemsBegeleider.map((item) => (
						<li className={styles.li}>
							<Link to={item.link}>
								{getIcon(item.icon)} {item.title}
							</Link>
						</li>
					))}
				</ul>
			</div>
			<div className={styles.divider}></div>

			<div className={styles.subitem}>
				<div className={styles.submenu}>Applicatie</div>
				<ul>
					{menuItemsApplicatie.map((item) => (
						<li className={styles.li}>
							<Link to={item.link}>
								{getIcon(item.icon)} {item.title}
							</Link>
						</li>
					))}
				</ul>
			</div>
			<div className={styles.divider}></div>
			<div>
				{version.database_env === "development" ? (
					<div className={styles.menu2}>
						DIT IS EEN TESTOMGEVING. GEBRUIK GEEN ECHTE DATA.
					</div>
				) : (
					"Production version"
				)}
			</div>
			<div className={styles.divider}></div>
			<div className={styles.thanks}>
				<strong>Created by:</strong>
			</div>
			<div className={styles.thanks}>{version.author}</div>
			<div className={styles.thanks}>
				<br></br>
				<strong>License:</strong>
			</div>
			<div className={styles.thanks}>{version.license}</div>
		</div>
	);
}

export default Sidemenu;
