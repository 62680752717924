import React, { useState, useEffect } from "react";
import Styles from "./Styles.jsx";
import axios from "axios";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = Styles;

function Voorbereiding(props) {
	const id = props.id;

	const [data, setData] = useState([]);
	const [initiation, setInitiation] = useState([]);
	const [BSN_check, setBSN_check] = useState(false);
	const [BSN_check_date, setBSN_check_date] = useState("1900-01-01");
	const [check_digid, setCheck_digid] = useState(false);
	const [check_digid_date, setCheck_digid_date] = useState("1900-01-01");
	const [afspraak_huurovk, setAfspraak_huurovk] = useState(false);
	const [afspraak_huurovk_date, setAfspraak_huurovk_date] =
		useState("1900-01-01");
	const [opvr_link_uitkeringrsd, setOpvr_link_uitkeringrsd] = useState(false);
	const [opvr_link_uitkeringrsd_date, setOpvr_link_uitkeringrsd_date] =
		useState("1900-01-01");
	const [opvr_borgst_kbnl, setOpvr_borgst_kbnl] = useState(false);
	const [opvr_borgst_kbnl_date, setOpvr_borgst_kbnl_date] =
		useState("1900-01-01");
	const [afspraak_int_rsd, setAfspraak_int_rsd] = useState(false);
	const [afpsraak_int_rsd_date, setAfpsraak_int_rsd_date] =
		useState("1900-01-01");
	const [vooraanmelding_huisarts, setVooraanmelding_huisarts] = useState(false);
	const [vooraanmelding_huisarts_date, setVooraanmelding_huisarts_date] =
		useState("1900-01-01");
	const [vooraanmelding_school, setVooraanmelding_school] = useState(false);
	const [vooraanmelding_school_date, setVooraanmelding_school_date] =
		useState("1900-01-01");
	const [overname_inboedel, setOvername_inboedel] = useState(false);
	const [overname_inboedel_date, setOvername_inboedel_date] =
		useState("1900-01-01");
	const [uitnodiging_regeldag, setUitnodiging_regeldag] = useState(false);
	const [uitnodiging_regeldag_date, setUitnodiging_regeldag_date] =
		useState("1900-01-01");
	const [afspraak_inschrijvingBRP, setAfspraak_inschrijvingBRP] =
		useState(false);
	const [afspraak_inschrijvingBRP_date, setAfspraak_inschrijvingBRP_date] =
		useState("1900-01-01");
	const [first_info_buren, setFirst_info_buren] = useState(false);
	const [first_info_buren_date, setFirst_info_buren_date] =
		useState("1900-01-01");
	const voorbereiding = "/api/voorbereiding/" + id;
	const updateurl = "/api/voorbereidingupdate/" + id;
	const [render, setRender] = useState(0);
	const savedata = {
		BSN_check: BSN_check,
		BSN_check_date: BSN_check_date,
		check_digid: check_digid,
		check_digid_date: check_digid_date,
		afspraak_huurovk: afspraak_huurovk,
		afspraak_huurovk_date: afspraak_huurovk_date,
		opvr_link_uitkeringrsd: opvr_link_uitkeringrsd,
		opvr_link_uitkeringrsd_date: opvr_link_uitkeringrsd_date,
		opvr_borgst_kbnl: opvr_borgst_kbnl,
		opvr_borgst_kbnl_date: opvr_borgst_kbnl_date,
		afspraak_int_rsd: afspraak_int_rsd,
		afpsraak_int_rsd_date: afpsraak_int_rsd_date,
		vooraanmelding_huisarts: vooraanmelding_huisarts,
		vooraanmelding_huisarts_date: vooraanmelding_huisarts_date,
		vooraanmelding_school: vooraanmelding_school,
		vooraanmelding_school_date: vooraanmelding_school_date,
		overname_inboedel: overname_inboedel,
		overname_inboedel_date: overname_inboedel_date,
		uitnodiging_regeldag: uitnodiging_regeldag,
		uitnodiging_regeldag_date: uitnodiging_regeldag_date,
		afspraak_inschrijvingBRP: afspraak_inschrijvingBRP,
		afspraak_inschrijvingBRP_date: afspraak_inschrijvingBRP_date,
		first_info_buren: first_info_buren,
		first_info_buren_date: first_info_buren_date,
		person_id: id,
	};

	useEffect(() => {
		console.log("Running useEffect in voorbereiding.js");
		axios({
			method: "get",
			url: voorbereiding,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res.status);

				setData(res.data);
				setInitiation(0);
			})
			.then((res) => {
				setRender(1);
			})
			.catch((err) => {
				setInitiation(1);
			});
	}, []);

	useEffect(() => {
		setBSN_check(data.BSN_check);
		setBSN_check_date(data.BSN_check_date);
		setCheck_digid(data.check_digid);
		setCheck_digid_date(data.check_digid_date);
		setAfspraak_huurovk(data.afspraak_huurovk);
		setAfspraak_huurovk_date(data.afspraak_huurovk_date);
		setOpvr_link_uitkeringrsd(data.opvr_link_uitkeringrsd);
		setOpvr_link_uitkeringrsd_date(data.opvr_link_uitkeringrsd_date);
		setOpvr_borgst_kbnl(data.opvr_borgst_kbnl);
		setOpvr_borgst_kbnl_date(data.opvr_borgst_kbnl_date);
		setAfspraak_int_rsd(data.afspraak_int_rsd);
		setAfpsraak_int_rsd_date(data.afpsraak_int_rsd_date);
		setVooraanmelding_huisarts(data.vooraanmelding_huisarts);
		setVooraanmelding_huisarts_date(data.vooraanmelding_huisarts_date);
		setVooraanmelding_school(data.vooraanmelding_school);
		setVooraanmelding_school_date(data.vooraanmelding_school_date);
		setOvername_inboedel(data.overname_inboedel);
		setOvername_inboedel_date(data.overname_inboedel_date);
		setUitnodiging_regeldag(data.uitnodiging_regeldag);
		setUitnodiging_regeldag_date(data.uitnodiging_regeldag_date);
		setAfspraak_inschrijvingBRP(data.afspraak_inschrijvingBRP);
		setAfspraak_inschrijvingBRP_date(data.afspraak_inschrijvingBRP_date);
		setFirst_info_buren(data.first_info_buren);
		setFirst_info_buren_date(data.first_info_buren_date);
	}, [render]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (initiation === 1) {
			axios({
				method: "post",
				url: "/api/voorbereidingsaved",
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res);
					setInitiation(0);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			axios({
				method: "PUT",
				url: updateurl,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div>
			<button className={styles.button} onClick={handleSubmit}>
				<FontAwesomeIcon icon={faPaperPlane} /> Opslaan
			</button>
			<div>
				<div>
					<form className={styles.checklist}>
						<label className={styles.label}>BSN Nummer verkregen</label>
						<select
							className={
								BSN_check == true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="BSN_check"
							name="BSN_check"
							value={BSN_check}
							onChange={(e) => {
								BSN_check === true ? setBSN_check(false) : setBSN_check(true);
								setBSN_check_date(new Date().toISOString().slice(0, 10));

								console.log(BSN_check);
							}}
						>
							<option value="true">Ja</option>
							<option value="false">Nee</option>
						</select>

						<input
							type="date"
							id="BSN_check_date"
							name="BSN_check_date"
							value={BSN_check_date}
							onChange={(e) => setBSN_check_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>
							Digid aangevraagd en verkregen
						</label>
						<select
							className={
								check_digid === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="check_digid"
							name="check_digid"
							value={check_digid}
							onChange={(e) => {
								check_digid === true
									? setCheck_digid(false)
									: setCheck_digid(true);
								setCheck_digid_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="check_digid_date"
							name="check_digid_date"
							value={check_digid_date}
							onChange={(e) => setCheck_digid_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>
							Afspraak huurovereenkomst/huurovereenkomst
						</label>
						<select
							className={
								afspraak_huurovk === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="afspraak_huurovk"
							name="afspraak_huurovk"
							value={afspraak_huurovk}
							onChange={(e) => {
								afspraak_huurovk === true
									? setAfspraak_huurovk(false)
									: setAfspraak_huurovk(true);
								setAfspraak_huurovk_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="afspraak_huurovk_date"
							name="Afpraak_huurovk_date"
							value={afspraak_huurovk_date}
							onChange={(e) => setAfspraak_huurovk_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>
							Opvragen link uitkering toeslag RSD
						</label>
						<select
							className={
								opvr_link_uitkeringrsd === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="opvr_link_uitkeringrsd"
							name="opvr_link_uitkeringrsd"
							value={opvr_link_uitkeringrsd}
							onChange={(e) => {
								opvr_link_uitkeringrsd === true
									? setOpvr_link_uitkeringrsd(false)
									: setOpvr_link_uitkeringrsd(true);
								setOpvr_link_uitkeringrsd_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="opvr_link_uitkeringrsd_date"
							name="opvr_link_uitkeringrsd_date"
							value={opvr_link_uitkeringrsd_date}
							onChange={(e) => setOpvr_link_uitkeringrsd_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>
							Opvragen borgstellingsovereenkomst KBNL
						</label>
						<select
							className={
								opvr_borgst_kbnl === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="opvr_borgst_kbnl"
							name="opvr_borgst_kbnl"
							value={opvr_borgst_kbnl}
							onChange={(e) => {
								opvr_borgst_kbnl === true
									? setOpvr_borgst_kbnl(false)
									: setOpvr_borgst_kbnl(true);
								setOpvr_borgst_kbnl_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="opvr_borgst_kbnl_date"
							name="opvr_borgst_kbnl_date"
							value={opvr_borgst_kbnl_date}
							onChange={(e) => setOpvr_borgst_kbnl_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Afspraak intake RSD</label>
						<select
							className={
								afspraak_int_rsd === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="afspraak_int_rsd"
							name="afspraak_int_rsd"
							value={afspraak_int_rsd}
							onChange={(e) => {
								afspraak_int_rsd === true
									? setAfspraak_int_rsd(false)
									: setAfspraak_int_rsd(true);
								setAfpsraak_int_rsd_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="afpsraak_int_rsd_date"
							name="afpsraak_int_rsd_date"
							value={afpsraak_int_rsd_date}
							onChange={(e) => setAfpsraak_int_rsd_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Vooraanmelding Huisarts</label>
						<select
							className={
								vooraanmelding_huisarts === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="vooraanmelding_huisarts"
							name="vooraanmelding_huisarts"
							value={vooraanmelding_huisarts}
							onChange={(e) => {
								vooraanmelding_huisarts === true
									? setVooraanmelding_huisarts(false)
									: setVooraanmelding_huisarts(true);
								setVooraanmelding_huisarts_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="vooraanmelding_huisarts_date"
							name="vooraanmelding_huisarts_date"
							value={vooraanmelding_huisarts_date}
							onChange={(e) => setVooraanmelding_huisarts_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Vooraanmelding School</label>
						<select
							className={
								vooraanmelding_school === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="vooraanmelding_school"
							name="vooraanmelding_school"
							value={vooraanmelding_school}
							onChange={(e) => {
								vooraanmelding_school === true
									? setVooraanmelding_school(false)
									: setVooraanmelding_school(true);
								setVooraanmelding_school_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="vooraanmelding_school_date"
							name="vooraanmelding_school_date"
							value={vooraanmelding_school_date}
							onChange={(e) => setVooraanmelding_school_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Overname inboedel</label>
						<select
							className={
								overname_inboedel === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="overname_inboedel"
							name="overname_inboedel"
							value={overname_inboedel}
							onChange={(e) => {
								overname_inboedel === true
									? setOvername_inboedel(false)
									: setOvername_inboedel(true);
								setOvername_inboedel_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="overname_inboedel_date"
							name="overname_inboedel_date"
							value={overname_inboedel_date}
							onChange={(e) => setOvername_inboedel_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Uitnodiging regeldag</label>
						<select
							className={
								uitnodiging_regeldag === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="uitnodiging_regeldag"
							name="uitnodiging_regeldag"
							value={uitnodiging_regeldag}
							onChange={(e) => {
								uitnodiging_regeldag === true
									? setUitnodiging_regeldag(false)
									: setUitnodiging_regeldag(true);
								setUitnodiging_regeldag_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="uitnodiging_regeldag_date"
							name="uitnodiging_regeldag_date"
							value={uitnodiging_regeldag_date}
							onChange={(e) => setUitnodiging_regeldag_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Afspraak inschrijving BRP</label>
						<select
							className={
								afspraak_inschrijvingBRP === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="afspraak_inschrijvingBRP"
							name="afspraak_inschrijvingBRP"
							value={afspraak_inschrijvingBRP}
							onChange={(e) => {
								afspraak_inschrijvingBRP === true
									? setAfspraak_inschrijvingBRP(false)
									: setAfspraak_inschrijvingBRP(true);
								setAfspraak_inschrijvingBRP_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="afspraak_inschrijvingBRP_date"
							name="afspraak_inschrijvingBRP_date"
							value={afspraak_inschrijvingBRP_date}
							onChange={(e) => setAfspraak_inschrijvingBRP_date(e.target.value)}
							className={styles.checkdate}
						/>

						<label className={styles.label}>Eerste informatie buren</label>
						<select
							className={
								first_info_buren === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="first_info_buren"
							name="first_info_buren"
							value={first_info_buren}
							onChange={(e) => {
								first_info_buren === true
									? setFirst_info_buren(false)
									: setFirst_info_buren(true);
								setFirst_info_buren_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="first_info_buren_date"
							name="first_info_buren_date"
							value={first_info_buren_date}
							onChange={(e) => setFirst_info_buren_date(e.target.value)}
							className={styles.checkdate}
						/>
					</form>
					<button className={styles.button} onClick={handleSubmit}>
						<FontAwesomeIcon icon={faPaperPlane} /> Opslaan
					</button>
				</div>
			</div>
		</div>
	);
}

export default Voorbereiding;
