import React, { useState } from "react";

function useToken() {
  function getToken() {
    const userToken = localStorage.getItem("token");
    return userToken && userToken;
  }

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState({});

  function saveToken(userToken) {
    localStorage.setItem("token", userToken);
    setToken(userToken);
  }

  function saveUser(user) {
    setUser(user);
  }

  function removeToken() {
    localStorage.removeItem("token");
    setToken(null);
  }

  return { setToken: saveToken, setUser: saveUser, user, token, removeToken };
}

export default useToken;
