import React, { useState, useEffect } from "react";
import axios from "axios";
import Styles from "./Styles.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";

const styles = Styles;

function Week1en2(props) {
	const persoon_id = props.id;
	const [data_algemeen, setData_algemeen] = useState([]);
	const [data_wonen, setData_wonen] = useState([]);
	const [data_fiscaal, setData_fiscaal] = useState([]);
	const [data_buurt, setData_buurt] = useState([]);
	const [initiation, setInitiation] = useState(1);

	//first part of statevars is related to WeekFirstSecond_algemeenmodel
	const [aanmelden_zorverzekering, setAanmelden_zorgverzekering] =
		useState(false);
	const [aanmelden_zorgverzekering_date, setAanmelden_zorgverzekering_date] =
		useState("1900-01-01");
	const [basisonderwijs, setBasisonderwijs] = useState(false);
	const [basisonderwijs_date, setBasisonderwijs_date] = useState("1900-01-01");
	const [voortgezet_onderwijs, setVoortgezet_onderwijs] = useState(false);
	const [voortgezet_onderwijs_date, setVoortgezet_onderwijs_date] =
		useState("1900-01-01");
	const [aanmelding_huisarts, setAanmelding_huisarts] = useState(false);
	const [aanmelding_huisarts_date, setAanmelding_huisarts_date] =
		useState("1900-01-01");
	const [informatie_vw, setInformatie_vw] = useState(false);
	const [informatie_vw_date, setInformatie_vw_date] = useState("1900-01-01");
	const [internetbankieren, setInternetbankieren] = useState(false);
	const [internetbankieren_date, setInternetbankieren_date] =
		useState("1900-01-01");
	const [extra_bankrekening, setExtra_bankrekening] = useState(false);
	const [extra_bankrekening_date, setExtra_bankrekening_date] =
		useState("1900-01-01");
	const [en_of_bankrekening, setEn_of_bankrekening] = useState(false);
	const [en_of_bankrekening_date, setEn_of_bankrekening_date] =
		useState("1900-01-01");
	const [administratiemap_client, setAdministratiemap_client] = useState(false);
	const [administratiemap_client_date, setAdministratiemap_client_date] =
		useState("1900-01-01");
	const [B12_formulier_coa, setB12_formulier_coa] = useState(false);
	const [B12_formulier_coa_date, setB12_formulier_coa_date] =
		useState("1900-01-01");

	//first part of statevars is related to WeekFirstSecond_wonen
	const [energielevering, setEnergielevering] = useState(false);
	const [energielevering_date, setEnergielevering_date] =
		useState("1900-01-01");
	const [boiler_geiser_huur, setBoiler_geiser_huur] = useState(false);
	const [boiler_geiser_huur_date, setBoiler_geiser_huur_date] =
		useState("1900-01-01");
	const [drinkwaterlevering, setDrinkwaterlevering] = useState(false);
	const [drinkwaterlevering_date, setDrinkwaterlevering_date] =
		useState("1900-01-01");
	const [tv_internet_telefoon, setTv_internet_telefoon] = useState(false);
	const [tv_internet_telefoon_date, setTv_internet_telefoon_date] =
		useState("1900-01-01");
	const [adreswijziging, setAdreswijziging] = useState(false);
	const [adreswijziging_date, setAdreswijziging_date] = useState("1900-01-01");
	const [woonverzekering, setWoonverzekering] = useState(false);
	const [woonverzekering_date, setWoonverzekering_date] =
		useState("1900-01-01");
	const [advies_inrichting, setAdvies_inrichting] = useState(false);
	const [advies_inrichting_date, setAdvies_inrichting_date] =
		useState("1900-01-01");
	const [advies_verwarmen_ventileren, setAdvies_verwarmen_ventileren] =
		useState(false);
	const [
		advies_verwarmen_ventileren_date,
		setAdvies_verwarmen_ventileren_date,
	] = useState("1900-01-01");
	const [afvalaanbieden, setAfvalaanbieden] = useState(false);
	const [afvalaanbieden_date, setAfvalaanbieden_date] = useState("1900-01-01");
	const [omgang_buren, setOmgang_buren] = useState(false);
	const [omgang_buren_date, setOmgang_buren_date] = useState("1900-01-01");
	const [kennismaking_buren, setKennismaking_buren] = useState(false);
	const [kennismaking_buren_date, setKennismaking_buren_date] =
		useState("1900-01-01");
	const [serviceabo_verhuurder, setServiceabo_verhuurder] = useState(false);
	const [serviceabo_verhuurder_date, setServiceabo_verhuurder_date] =
		useState("1900-01-01");

	//first part of statevars is related to WeekFirstSecond_fiscaal
	const [huurtoeslag, setHuurtoeslag] = useState(false);
	const [huurtoeslag_date, setHuurtoeslag_date] = useState("1900-01-01");
	const [zorgtoeslag, setZorgtoeslag] = useState(false);
	const [zorgtoeslag_date, setZorgtoeslag_date] = useState("1900-01-01");
	const [kindgebonden_budget, setKindgebonden_budget] = useState(false);
	const [kindgebonden_budget_date, setKindgebonden_budget_date] =
		useState("1900-01-01");
	const [teruggaaf_ib, setTeruggaaf_ib] = useState(false);
	const [teruggaaf_ib_date, setTeruggaaf_ib_date] = useState("1900-01-01");
	const [kwijtschelding_lokale_belasting, setKwijtschelding_lokale_belasting] =
		useState(false);
	const [
		kwijtschelding_lokale_belasting_date,
		setKwijtschelding_lokale_belasting_date,
	] = useState("1900-01-01");
	const [
		kwijtschelding_watersch_belasting,
		setKwijtschelding_watersch_belasting,
	] = useState(false);
	const [
		kwijtschelding_watersch_belasting_date,
		setKwijtschelding_watersch_belasting_date,
	] = useState("1900-01-01");

	//first part of statevars is related to WeekFirstSecond_buurt
	const [winkels, setWinkels] = useState(false);
	const [winkels_date, setWinkels_date] = useState("1900-01-01");
	const [ov, setOv] = useState(false);
	const [ov_date, setOv_date] = useState("1900-01-01");
	const [ov_chipkaarten, setOv_chipkaarten] = useState(false);
	const [ov_chipkaarten_date, setOv_chipkaarten_date] = useState("1900-01-01");

	const savedata = {
		aanmelden_zorgverzekering: aanmelden_zorverzekering,
		aanmelden_zorgverzekering_date: aanmelden_zorgverzekering_date,
		basisonderwijs: basisonderwijs,
		basisonderwijs_date: basisonderwijs_date,
		voortgezet_onderwijs: voortgezet_onderwijs,
		voortgezet_onderwijs_date: voortgezet_onderwijs_date,
		aanmelding_huisarts: aanmelding_huisarts,
		aanmelding_huisarts_date: aanmelding_huisarts_date,
		informatie_vw: informatie_vw,
		informatie_vw_date: informatie_vw_date,
		internetbankieren: internetbankieren,
		internetbankieren_date: internetbankieren_date,
		extra_bankrekening: extra_bankrekening,
		extra_bankrekening_date: extra_bankrekening_date,
		en_of_bankrekening: en_of_bankrekening,
		en_of_bankrekening_date: en_of_bankrekening_date,
		administratiemap_client: administratiemap_client,
		administratiemap_client_date: administratiemap_client_date,
		B12_formulier_coa: B12_formulier_coa,
		B12_formulier_coa_date: B12_formulier_coa_date,
		energielevering: energielevering,
		energielevering_date: energielevering_date,
		boiler_geiser_huur: boiler_geiser_huur,
		boiler_geiser_huur_date: boiler_geiser_huur_date,
		drinkwaterlevering: drinkwaterlevering,
		drinkwaterlevering_date: drinkwaterlevering_date,
		tv_internet_telefoon: tv_internet_telefoon,
		tv_internet_telefoon_date: tv_internet_telefoon_date,
		adreswijziging: adreswijziging,
		adreswijziging_date: adreswijziging_date,
		woonverzekering: woonverzekering,
		woonverzekering_date: woonverzekering_date,
		advies_inrichting: advies_inrichting,
		advies_inrichting_date: advies_inrichting_date,
		advies_verwarmen_ventileren: advies_verwarmen_ventileren,
		advies_verwarmen_ventileren_date: advies_verwarmen_ventileren_date,
		afvalaanbieden: afvalaanbieden,
		afvalaanbieden_date: afvalaanbieden_date,
		omgang_buren: omgang_buren,
		omgang_buren_date: omgang_buren_date,
		kennismaking_buren: kennismaking_buren,
		kennismaking_buren_date: kennismaking_buren_date,
		serviceabo_verhuurder: serviceabo_verhuurder,
		serviceabo_verhuurder_date: serviceabo_verhuurder_date,
		huurtoeslag: huurtoeslag,
		huurtoeslag_date: huurtoeslag_date,
		zorgtoeslag: zorgtoeslag,
		zorgtoeslag_date: zorgtoeslag_date,
		kindgebonden_budget: kindgebonden_budget,
		kindgebonden_budget_date: kindgebonden_budget_date,
		teruggaaf_ib: teruggaaf_ib,
		teruggaaf_ib_date: teruggaaf_ib_date,
		kwijtschelding_lokale_belasting: kwijtschelding_lokale_belasting,
		kwijtschelding_lokale_belasting_date: kwijtschelding_lokale_belasting_date,
		kwijtschelding_watersch_belasting: kwijtschelding_watersch_belasting,
		kwijtschelding_watersch_belasting_date:
			kwijtschelding_watersch_belasting_date,
		winkels: winkels,
		winkels_date: winkels_date,
		ov: ov,
		ov_date: ov_date,
		ov_chipkaarten: ov_chipkaarten,
		ov_chipkaarten_date: ov_chipkaarten_date,
	};

	const [render, setRender] = useState(false);
	const week1en2 = "/api/week1en2/" + persoon_id;
	const week1en2save = "/api/week1en2save/" + persoon_id;
	const week1en2update = "/api/week1en2update/" + persoon_id;

	useEffect(() => {
		axios({
			method: "GET",
			url: week1en2,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setData_algemeen(res.data.algemeen);
				setData_fiscaal(res.data.fiscaal);
				setData_wonen(res.data.wonen);
				setData_buurt(res.data.buurt);
				setInitiation(0);
			})
			.then((res) => {
				setRender(true);
			})
			.catch((err) => {
				console.log(err);
				setInitiation(1);
			});
	}, []);

	useEffect(() => {
		setAanmelden_zorgverzekering(data_algemeen.aanmelden_zorgverzekering);
		setAanmelden_zorgverzekering_date(
			data_algemeen.aanmelden_zorgverzekering_date
		);
		setBasisonderwijs(data_algemeen.basisonderwijs);
		setBasisonderwijs_date(data_algemeen.basisonderwijs_date);
		setVoortgezet_onderwijs(data_algemeen.voortgezet_onderwijs);
		setVoortgezet_onderwijs_date(data_algemeen.voortgezet_onderwijs_date);
		setAanmelding_huisarts(data_algemeen.aanmelding_huisarts);
		setAanmelding_huisarts_date(data_algemeen.aanmelding_huisarts_date);
		setInformatie_vw(data_algemeen.informatie_vw);
		setInformatie_vw_date(data_algemeen.informatie_vw_date);
		setInternetbankieren(data_algemeen.internetbankieren);
		setInternetbankieren_date(data_algemeen.internetbankieren_date);
		setExtra_bankrekening(data_algemeen.extra_bankrekening);
		setExtra_bankrekening_date(data_algemeen.extra_bankrekening_date);
		setEn_of_bankrekening(data_algemeen.en_of_bankrekening);
		setEn_of_bankrekening_date(data_algemeen.en_of_bankrekening_date);
		setAdministratiemap_client(data_algemeen.administratiemap_client);
		setAdministratiemap_client_date(data_algemeen.administratiemap_client_date);
		setB12_formulier_coa(data_algemeen.B12_formulier_coa);
		setB12_formulier_coa_date(data_algemeen.B12_formulier_coa_date);
		setEnergielevering(data_wonen.energielevering);
		setEnergielevering_date(data_wonen.energielevering_date);
		setBoiler_geiser_huur(data_wonen.boiler_geiser_huur);
		setBoiler_geiser_huur_date(data_wonen.boiler_geiser_huur_date);
		setDrinkwaterlevering(data_wonen.drinkwaterlevering);
		setDrinkwaterlevering_date(data_wonen.drinkwaterlevering_date);
		setTv_internet_telefoon(data_wonen.tv_internet_telefoon);
		setTv_internet_telefoon_date(data_wonen.tv_internet_telefoon_date);
		setAdreswijziging(data_wonen.adreswijziging);
		setAdreswijziging_date(data_wonen.adreswijziging_date);
		setWoonverzekering(data_wonen.woonverzekering);
		setWoonverzekering_date(data_wonen.woonverzekering_date);
		setAdvies_inrichting(data_wonen.advies_inrichting);
		setAdvies_inrichting_date(data_wonen.advies_inrichting_date);
		setAdvies_verwarmen_ventileren(data_wonen.advies_verwarmen_ventileren);
		setAdvies_verwarmen_ventileren_date(data_wonen.advies_inrichting_date);
		setAfvalaanbieden(data_wonen.afvalaanbieden);
		setAfvalaanbieden_date(data_wonen.afvalaanbieden_date);
		setOmgang_buren(data_wonen.omgang_buren);
		setOmgang_buren(data_wonen.omgang_buren);
		setOmgang_buren_date(data_wonen.omgang_buren_date);
		setKennismaking_buren(data_wonen.kennismaking_buren);
		setKennismaking_buren_date(data_wonen.kennismaking_buren_date);
		setServiceabo_verhuurder(data_wonen.serviceabo_verhuurder);
		setServiceabo_verhuurder_date(data_wonen.serviceabo_verhuurder_date);
		setHuurtoeslag(data_fiscaal.huurtoeslag);
		setHuurtoeslag_date(data_fiscaal.huurtoeslag_date);
		setZorgtoeslag(data_fiscaal.zorgtoeslag);
		setZorgtoeslag_date(data_fiscaal.zorgtoeslag_date);
		setKindgebonden_budget(data_fiscaal.kindgebonden_budget);
		setKindgebonden_budget_date(data_fiscaal.kindgebonden_budget_date);
		setTeruggaaf_ib(data_fiscaal.teruggaaf_ib);
		setTeruggaaf_ib_date(data_fiscaal.teruggaaf_ib_date);
		setKwijtschelding_lokale_belasting(
			data_fiscaal.kwijtschelding_lokale_belasting
		);
		setKwijtschelding_lokale_belasting_date(
			data_fiscaal.kwijtschelding_lokale_belasting_date
		);
		setKwijtschelding_watersch_belasting(
			data_fiscaal.kwijtschelding_watersch_belasting
		);
		setKwijtschelding_watersch_belasting_date(
			data_fiscaal.kwijtschelding_watersch_belasting_date
		);
		setWinkels(data_buurt.winkels);
		setWinkels_date(data_buurt.winkels_date);
		setOv(data_buurt.ov);
		setOv_date(data_buurt.ov_date);
		setOv_chipkaarten(data_buurt.ov_chipkaarten);
		setOv_chipkaarten_date(data_buurt.ov_chipkaarten_date);
	}, [render]);

	const handleSubmit = (e) => {
		if (initiation === 1) {
			e.preventDefault();
			axios({
				method: "POST",
				url: week1en2save,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res.status);
					setInitiation(0);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			e.preventDefault();
			axios({
				method: "PUT",
				url: week1en2update,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
	return (
		<div>
			{" "}
			<button onClick={handleSubmit} className={styles.button}>
				<FontAwesomeIcon icon={faPaperPlane} className={styles.iconbutton} />{" "}
				Opslaan
			</button>
			<div>
				<div className={styles.columnar}>
					<div>
						<p className={styles.columnhead}>Algemeen</p>
						<form className={styles.checklistmulti}>
							<label className={styles.label}>Aanmelden zorgverzekering</label>
							<select
								className={
									aanmelden_zorverzekering == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="aanmelden_zorverzekering"
								name="aanmelden_zorverzekering"
								value={aanmelden_zorverzekering}
								onChange={(e) => {
									aanmelden_zorverzekering === true
										? setAanmelden_zorgverzekering(false)
										: setAanmelden_zorgverzekering(true);
									setAanmelden_zorgverzekering_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="aanmelden_zorgverzekering_date"
								name="aanmelden_zorgverzekering_date"
								value={aanmelden_zorgverzekering_date}
								onChange={(e) =>
									setAanmelden_zorgverzekering_date(e.target.value)
								}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Basisonderwijs</label>
							<select
								className={
									basisonderwijs == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="basisonderwijs"
								name="basisonderwijs"
								value={basisonderwijs}
								onChange={(e) => {
									basisonderwijs === true
										? setBasisonderwijs(false)
										: setBasisonderwijs(true);
									setBasisonderwijs_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="basisonderwijs_date"
								name="basisonderwijs_date"
								value={basisonderwijs_date}
								onChange={(e) => setBasisonderwijs_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Voortgezet onderwijs</label>
							<select
								className={
									voortgezet_onderwijs == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="voortgezet_onderwijs"
								name="voortgezet_onderwijs"
								value={voortgezet_onderwijs}
								onChange={(e) => {
									voortgezet_onderwijs === true
										? setVoortgezet_onderwijs(false)
										: setVoortgezet_onderwijs(true);
									setVoortgezet_onderwijs_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="voortgezet_onderwijs_date"
								name="voortgezet_onderwijs_date"
								value={voortgezet_onderwijs_date}
								onChange={(e) => setVoortgezet_onderwijs_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Aanmelding Huisarts</label>
							<select
								className={
									aanmelding_huisarts == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="aanmelding_huisarts"
								name="aanmelding_huisarts"
								value={aanmelding_huisarts}
								onChange={(e) => {
									aanmelding_huisarts === true
										? setAanmelding_huisarts(false)
										: setAanmelding_huisarts(true);
									setAanmelding_huisarts_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="aanmelding_huisarts_date"
								name="aanmelding_huisarts_date"
								value={aanmelding_huisarts_date}
								onChange={(e) => setAanmelding_huisarts_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>
								Informatie vluchtelingenwerk
							</label>
							<select
								className={
									informatie_vw == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="informatie_vw"
								name="informatie_vw"
								value={informatie_vw}
								onChange={(e) => {
									informatie_vw === true
										? setInformatie_vw(false)
										: setInformatie_vw(true);
									setInformatie_vw_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="informatie_vw_date"
								name="informatie_vw_date"
								value={informatie_vw_date}
								onChange={(e) => setInformatie_vw_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Internetbankieren</label>
							<select
								className={
									internetbankieren == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="internetbankieren"
								name="internetbankieren"
								value={internetbankieren}
								onChange={(e) => {
									internetbankieren === true
										? setInternetbankieren(false)
										: setInternetbankieren(true);
									setInternetbankieren_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="internetbankieren_date"
								name="internetbankieren_date"
								value={internetbankieren_date}
								onChange={(e) => setInternetbankieren_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Extra bankrekening</label>
							<select
								className={
									extra_bankrekening == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="extra_bankrekening"
								name="extra_bankrekening"
								value={extra_bankrekening}
								onChange={(e) => {
									extra_bankrekening === true
										? setExtra_bankrekening(false)
										: setExtra_bankrekening(true);
									setExtra_bankrekening_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="extra_bankrekening_date"
								name="extra_bankrekening_date"
								value={extra_bankrekening_date}
								onChange={(e) => setExtra_bankrekening_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>En/of rekening</label>
							<select
								className={
									en_of_bankrekening == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="en_of_bankrekening"
								name="en_of_bankrekening"
								value={en_of_bankrekening}
								onChange={(e) => {
									en_of_bankrekening === true
										? setEn_of_bankrekening(false)
										: setEn_of_bankrekening(true);
									setEn_of_bankrekening_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="en_of_bankrekening_date"
								name="en_of_bankrekening_date"
								value={en_of_bankrekening_date}
								onChange={(e) => setEn_of_bankrekening_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Administratiemap client</label>
							<select
								className={
									administratiemap_client == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="administratiemap_client"
								name="administratiemap_client"
								value={administratiemap_client}
								onChange={(e) => {
									administratiemap_client === true
										? setAdministratiemap_client(false)
										: setAdministratiemap_client(true);
									setAdministratiemap_client_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="administratiemap_client_date"
								name="administratiemap_client_date"
								value={administratiemap_client_date}
								onChange={(e) =>
									setAdministratiemap_client_date(e.target.value)
								}
								className={styles.checkdate}
							/>

							<label className={styles.label}>B12 formulier COA</label>
							<select
								className={
									B12_formulier_coa == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="B12_formulier_coa"
								name="B12_formulier_coa"
								value={B12_formulier_coa}
								onChange={(e) => {
									B12_formulier_coa === true
										? setB12_formulier_coa(false)
										: setB12_formulier_coa(true);
									setB12_formulier_coa_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="B12_formulier_coa_date"
								name="B12_formulier_coa_date"
								value={B12_formulier_coa_date}
								onChange={(e) => setB12_formulier_coa_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
					</div>
					<div>
						<p className={styles.columnhead}>Wonen</p>
						<form className={styles.checklistmulti}>
							<label className={styles.label}>Energielevering</label>
							<select
								className={
									energielevering == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="energielevering"
								name="energielevering"
								value={energielevering}
								onChange={(e) => {
									energielevering === true
										? setEnergielevering(false)
										: setEnergielevering(true);
									setEnergielevering_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="energielevering_date"
								name="energielevering_date"
								value={energielevering_date}
								onChange={(e) => setEnergielevering_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Boiler- of geiserhuur</label>
							<select
								className={
									boiler_geiser_huur == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="boiler_geiser_huur"
								name="boiler_geiser_huur"
								value={boiler_geiser_huur}
								onChange={(e) => {
									boiler_geiser_huur === true
										? setBoiler_geiser_huur(false)
										: setBoiler_geiser_huur(true);
									setBoiler_geiser_huur_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="boiler_geiser_huur_date"
								name="boiler_geiser_huur_date"
								value={boiler_geiser_huur_date}
								onChange={(e) => setBoiler_geiser_huur_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Drinkwaterlevering</label>
							<select
								className={
									drinkwaterlevering == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="drinkwaterlevering"
								name="drinkwaterlevering"
								value={drinkwaterlevering}
								onChange={(e) => {
									drinkwaterlevering === true
										? setDrinkwaterlevering(false)
										: setDrinkwaterlevering(true);
									setDrinkwaterlevering_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="drinkwaterlevering_date"
								name="drinkwaterlevering_date"
								value={drinkwaterlevering_date}
								onChange={(e) => setDrinkwaterlevering_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>TV, internet en telefoon</label>
							<select
								className={
									tv_internet_telefoon == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="tv_internet_telefoon"
								name="tv_internet_telefoon"
								value={tv_internet_telefoon}
								onChange={(e) => {
									tv_internet_telefoon === true
										? setTv_internet_telefoon(false)
										: setTv_internet_telefoon(true);
									setTv_internet_telefoon_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="tv_internet_telefoon_date"
								name="tv_internet_telefoon_date"
								value={tv_internet_telefoon_date}
								onChange={(e) => setTv_internet_telefoon_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Adreswijziging</label>
							<select
								className={
									adreswijziging == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="adreswijziging"
								name="adreswijziging"
								value={adreswijziging}
								onChange={(e) => {
									adreswijziging === true
										? setAdreswijziging(false)
										: setAdreswijziging(true);
									setAdreswijziging_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="adreswijziging_date"
								name="adreswijziging_date"
								value={adreswijziging_date}
								onChange={(e) => setAdreswijziging_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Woonverzekering</label>
							<select
								className={
									woonverzekering == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="woonverzekering"
								name="woonverzekering"
								value={woonverzekering}
								onChange={(e) => {
									woonverzekering === true
										? setWoonverzekering(false)
										: setWoonverzekering(true);
									setWoonverzekering_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="woonverzekering_date"
								name="woonverzekering_date"
								value={woonverzekering_date}
								onChange={(e) => setWoonverzekering_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Advies inrichting</label>
							<select
								className={
									advies_inrichting == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="advies_inrichting"
								name="advies_inrichting"
								value={advies_inrichting}
								onChange={(e) => {
									advies_inrichting === true
										? setAdvies_inrichting(false)
										: setAdvies_inrichting(true);
									setAdvies_inrichting_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="advies_inrichting_date"
								name="advies_inrichting_date"
								value={advies_inrichting_date}
								onChange={(e) => setAdvies_inrichting_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>
								Advies verwarmen en ventileren
							</label>
							<select
								className={
									advies_verwarmen_ventileren == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="advies_verwarmen_ventileren"
								name="advies_verwarmen_ventileren"
								value={advies_verwarmen_ventileren}
								onChange={(e) => {
									advies_verwarmen_ventileren === true
										? setAdvies_verwarmen_ventileren(false)
										: setAdvies_verwarmen_ventileren(true);
									setAdvies_verwarmen_ventileren_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="advies_verwarmen_ventileren_date"
								name="advies_verwarmen_ventileren_date"
								value={advies_verwarmen_ventileren_date}
								onChange={(e) =>
									setAdvies_verwarmen_ventileren_date(e.target.value)
								}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Afval aanbieden</label>
							<select
								className={
									afvalaanbieden == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="afvalaanbieden"
								name="afvalaanbieden"
								value={afvalaanbieden}
								onChange={(e) => {
									afvalaanbieden === true
										? setAfvalaanbieden(false)
										: setAfvalaanbieden(true);
									setAfvalaanbieden_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="afvalaanbieden_date"
								name="afvalaanbieden_date"
								value={afvalaanbieden_date}
								onChange={(e) => setAfvalaanbieden_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Omgang buren</label>
							<select
								className={
									omgang_buren == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="omgang_buren"
								name="omgang_buren"
								value={omgang_buren}
								onChange={(e) => {
									omgang_buren === true
										? setOmgang_buren(false)
										: setOmgang_buren(true);
									setOmgang_buren_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="omgang_buren_date"
								name="omgang_buren_date"
								value={omgang_buren_date}
								onChange={(e) => setOmgang_buren_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Kennismaking buren</label>
							<select
								className={
									kennismaking_buren == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="kennismaking_buren"
								name="kennismaking_buren"
								value={kennismaking_buren}
								onChange={(e) => {
									kennismaking_buren === true
										? setKennismaking_buren(false)
										: setKennismaking_buren(true);
									setKennismaking_buren_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="kennismaking_buren_date"
								name="kennismaking_buren_date"
								value={kennismaking_buren_date}
								onChange={(e) => setKennismaking_buren_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>
								Serviceabonnement verhuurder
							</label>
							<select
								className={
									serviceabo_verhuurder == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="serviceabo_verhuurder"
								name="serviceabo_verhuurder"
								value={serviceabo_verhuurder}
								onChange={(e) => {
									serviceabo_verhuurder === true
										? setServiceabo_verhuurder(false)
										: setServiceabo_verhuurder(true);
									setServiceabo_verhuurder_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="serviceabo_verhuurder_date"
								name="serviceabo_verhuurder_date"
								value={serviceabo_verhuurder_date}
								onChange={(e) => setServiceabo_verhuurder_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
					</div>
					<div>
						<p className={styles.columnhead}>Fiscaal en toeslagen</p>
						<form className={styles.checklistmulti}>
							<label className={styles.label}>Huurtoeslag</label>
							<select
								className={
									huurtoeslag == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="huurtoeslag"
								name="huurtoeslag"
								value={huurtoeslag}
								onChange={(e) => {
									huurtoeslag === true
										? setHuurtoeslag(false)
										: setHuurtoeslag(true);
									setHuurtoeslag_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="huurtoeslag_date"
								name="huurtoeslag_date"
								value={huurtoeslag_date}
								onChange={(e) => setHuurtoeslag_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Zorgtoeslag</label>
							<select
								className={
									zorgtoeslag == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="zorgtoeslag"
								name="zorgtoeslag"
								value={zorgtoeslag}
								onChange={(e) => {
									zorgtoeslag === true
										? setZorgtoeslag(false)
										: setZorgtoeslag(true);
									setZorgtoeslag_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="zorgtoeslag_date"
								name="zorgtoeslag_date"
								value={zorgtoeslag_date}
								onChange={(e) => setZorgtoeslag_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Kindgebonden budget</label>
							<select
								className={
									kindgebonden_budget == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="kindgebonden_budget"
								name="kindgebonden_budget"
								value={kindgebonden_budget}
								onChange={(e) => {
									kindgebonden_budget === true
										? setKindgebonden_budget(false)
										: setKindgebonden_budget(true);
									setKindgebonden_budget_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="kindgebonden_budget_date"
								name="kindgebonden_budget_date"
								value={kindgebonden_budget_date}
								onChange={(e) => setKindgebonden_budget_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Teruggaaf IB</label>
							<select
								className={
									teruggaaf_ib == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="teruggaaf_ib"
								name="teruggaaf_ib"
								value={teruggaaf_ib}
								onChange={(e) => {
									teruggaaf_ib === true
										? setTeruggaaf_ib(false)
										: setTeruggaaf_ib(true);
									setTeruggaaf_ib_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="teruggaaf_ib_date"
								name="teruggaaf_ib_date"
								value={teruggaaf_ib_date}
								onChange={(e) => setTeruggaaf_ib_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>
								Kwijtschelding Lokale belastingen
							</label>
							<select
								className={
									kwijtschelding_lokale_belasting == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="kwijtschelding_lokale_belasting"
								name="kwijtschelding_lokale_belasting"
								value={kwijtschelding_lokale_belasting}
								onChange={(e) => {
									kwijtschelding_lokale_belasting === true
										? setKwijtschelding_lokale_belasting(false)
										: setKwijtschelding_lokale_belasting(true);
									setKwijtschelding_lokale_belasting_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="kwijtschelding_lokale_belasting_date"
								name="kwijtschelding_lokale_belasting_date"
								value={kwijtschelding_lokale_belasting_date}
								onChange={(e) =>
									setKwijtschelding_lokale_belasting_date(e.target.value)
								}
								className={styles.checkdate}
							/>

							<label className={styles.label}>
								Kwijtschelding waterschaps belastingen
							</label>
							<select
								className={
									kwijtschelding_watersch_belasting == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="kwijtschelding_watersch_belasting"
								name="kwijtschelding_watersch_belasting"
								value={kwijtschelding_watersch_belasting}
								onChange={(e) => {
									kwijtschelding_watersch_belasting === true
										? setKwijtschelding_watersch_belasting(false)
										: setKwijtschelding_watersch_belasting(true);
									setKwijtschelding_watersch_belasting_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="kwijtschelding_watersch_belasting_date"
								name="kwijtschelding_watersch_belasting_date"
								value={kwijtschelding_watersch_belasting_date}
								onChange={(e) =>
									setKwijtschelding_watersch_belasting_date(e.target.value)
								}
								className={styles.checkdate}
							/>
						</form>

						<p className={styles.columnhead}>
							<br />
							Omgevingsinformatie - de buurt
						</p>
						<form className={styles.checklistmulti}>
							<label className={styles.label}>Winkels</label>
							<select
								className={
									winkels == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="winkels"
								name="winkels"
								value={winkels}
								onChange={(e) => {
									winkels === true ? setWinkels(false) : setWinkels(true);
									setWinkels_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="winkels_date"
								name="winkels_date"
								value={winkels_date}
								onChange={(e) => setWinkels_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Openbaar vervoer</label>
							<select
								className={
									ov == true ? styles.checkselectcompl : styles.checkselectfirst
								}
								id="ov"
								name="ov"
								value={ov}
								onChange={(e) => {
									ov === true ? setOv(false) : setOv(true);
									setOv_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="ov_date"
								name="ov_date"
								value={ov_date}
								onChange={(e) => setOv_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>OV Chipkaarten</label>
							<select
								className={
									ov_chipkaarten == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="ov_chipkaarten"
								name="ov_chipkaarten"
								value={ov_chipkaarten}
								onChange={(e) => {
									ov_chipkaarten === true
										? setOv_chipkaarten(false)
										: setOv_chipkaarten(true);
									setOv_chipkaarten_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="ov_chipkaarten_date"
								name="ov_chipkaarten_date"
								value={ov_chipkaarten_date}
								onChange={(e) => setOv_chipkaarten_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
					</div>
				</div>
				<button onClick={handleSubmit} className={styles.button}>
					<FontAwesomeIcon icon={faPaperPlane} className={styles.iconbutton} />{" "}
					Opslaan
				</button>
			</div>
		</div>
	);
}

export default Week1en2;
