import React, { useState, useEffect } from "react";
import Styles from "./Styles.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";

const styles = Styles;

function Na2cq3weken(props) {
	const person_id = props.id;
	const [data_gezondheidszorg, setData_gezondheidszorg] = useState([]);
	const [data_kinderen, setData_kinderen] = useState([]);
	const [data_wetinburgering, setData_wetinburgering] = useState([]);
	const [data_gezinshereniging, setData_gezinshereniging] = useState([]);
	const [initiation, setInitiation] = useState(1);
	const [render, setRender] = useState(0);

	//consts gezondheidszorg
	const [kennismaking_huisarts, setKennismaking_huisarts] = useState(false);
	const [kennismaking_huisarts_date, setKennismaking_huisarts_date] =
		useState("1900-01-01");
	const [verlos_en_kraamzorg, setVerlos_en_kraamzorg] = useState(false);
	const [verlos_en_kraamzorg_date, setVerlos_en_kraamzorg_date] =
		useState("1900-01-01");
	const [aanmelding_tandarts, setAanmelding_tandarts] = useState(false);
	const [aanmelding_tandarts_date, setAanmelding_tandarts_date] =
		useState("1900-01-01");

	//const kinderen
	const [wsf, setWsf] = useState(false);
	const [wsf_date, setWsf_date] = useState("1900-01-01");
	const [kinderbijslag, setKinderbijslag] = useState(false);
	const [kinderbijslag_date, setKinderbijslag_date] = useState("1900-01-01");
	const [busvg_schoolkinderen, setBusvg_schoolkinderen] = useState(false);
	const [busvg_schoolkinderen_date, setBusvg_schoolkinderen_date] =
		useState("1900-01-01");
	const [peuteropvang, setPeuteropvang] = useState(false);
	const [peuteropvang_date, setPeuteropvang_date] = useState("1900-01-01");

	const [overblijfkosten_basisschool, setOverblijfkosten_basisschool] =
		useState(false);
	const [
		overblijfkosten_basisschool_date,
		setOverblijfkosten_basisschool_date,
	] = useState("1900-01-01");
	const [kinderopvang, setKinderopvang] = useState(false);
	const [kinderopvang_date, setKinderopvang_date] = useState("1900-01-01");

	//const wetinburgering
	const [inburgersplicht, setInburgersplicht] = useState(false);
	const [inburgersplicht_date, setInburgersplicht_date] =
		useState("1900-01-01");
	const [lening_duo, setLening_duo] = useState(false);
	const [lening_duo_date, setLening_duo_date] = useState("1900-01-01");
	const [keuze_inburgeringsaanbieder, setKeuze_inburgeringsaanbieder] =
		useState(false);
	const [
		keuze_inburgeringsaanbieder_date,
		setKeuze_inburgeringsaanbieder_date,
	] = useState("1900-01-01");
	const [vergoeding_reiskosten, setVergoeding_reiskosten] = useState(false);
	const [vergoeding_reiskosten_date, setVergoeding_reiskosten_date] =
		useState("1900-01-01");
	const [pvt, setPvt] = useState(false);
	const [pvt_date, setPvt_date] = useState("1900-01-01");
	const [taalhuis, setTaalhuis] = useState(false);
	const [taalhuis_date, setTaalhuis_date] = useState("1900-01-01");
	const [diploma_waardering, setDiploma_waardering] = useState(false);
	const [diploma_waardering_date, setDiploma_waardering_date] =
		useState("1900-01-01");

	//const gezinshereniging
	const [documenten, setDocumenten] = useState(false);
	const [documenten_date, setDocumenten_date] = useState("1900-01-01");
	const [aanvraag_ind, setAanvraag_ind] = useState(false);
	const [aanvraag_ind_date, setAanvraag_ind_date] = useState("1900-01-01");

	const weeksecondandthird = "/api/week2and3/" + person_id;
	const weeksecondandthirdsave = "/api/week2and3_save/" + person_id;
	const weeksecondandthridsupdate = "/api/week2and3_update/" + person_id;

	const savedata = {
		kennismaking_huisarts: kennismaking_huisarts,
		kennismaking_huisarts_date: kennismaking_huisarts_date,
		verlos_en_kraamzorg: verlos_en_kraamzorg,
		verlos_en_kraamzorg_date: verlos_en_kraamzorg_date,
		aanmelding_tandarts: aanmelding_tandarts,
		aanmelding_tandarts_date: aanmelding_tandarts_date,
		wsf: wsf,
		wsf_date: wsf_date,
		kinderbijslag: kinderbijslag,
		kinderbijslag_date: kinderbijslag_date,
		busvg_schoolkinderen: busvg_schoolkinderen,
		busvg_schoolkinderen_date: busvg_schoolkinderen_date,
		peuteropvang: peuteropvang,
		peuteropvang_date: peuteropvang_date,
		overblijfkosten_basisschool: overblijfkosten_basisschool,
		overblijfkosten_basisschool_date: overblijfkosten_basisschool_date,
		kinderopvang: kinderopvang,
		kinderopvang_date: kinderopvang_date,
		inburgersplicht: inburgersplicht,
		inburgersplicht_date: inburgersplicht_date,
		lening_duo: lening_duo,
		lening_duo_date: lening_duo_date,
		keuze_inburgeringsaanbieder: keuze_inburgeringsaanbieder,
		keuze_inburgeringsaanbieder_date: keuze_inburgeringsaanbieder_date,
		vergoeding_reiskosten: vergoeding_reiskosten,
		vergoeding_reiskosten_date: vergoeding_reiskosten_date,
		pvt: pvt,
		pvt_date: pvt_date,
		taalhuis: taalhuis,
		taalhuis_date: taalhuis_date,
		diploma_waardering: diploma_waardering,
		diploma_waardering_date: diploma_waardering_date,
		documenten: documenten,
		documenten_date: documenten_date,
		aanvraag_ind: aanvraag_ind,
		aanvraag_ind_date: aanvraag_ind_date,
		persoon_id: person_id,
	};

	useEffect(() => {
		axios({
			method: "GET",
			url: weeksecondandthird,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res.status);
				setData_gezinshereniging(res.data.gezinshereniging);
				setData_gezondheidszorg(res.data.gezondheidszorg);
				setData_wetinburgering(res.data.wet_inburgering);
				setData_kinderen(res.data.kinderen);
				console.log(res.data);

				setInitiation(0);
			})
			.then((res) => {
				setRender(true);
			})
			.catch((err) => {
				console.log(err);
				setInitiation(1);
			});
	}, []);

	useEffect(() => {
		setKennismaking_huisarts(data_gezondheidszorg.kennismaking_huisarts);
		setKennismaking_huisarts_date(
			data_gezondheidszorg.kennismaking_huisarts_date
		);
		setVerlos_en_kraamzorg(data_gezondheidszorg.verlos_en_kraamzorg);
		setVerlos_en_kraamzorg_date(data_gezondheidszorg.verlos_en_kraamzorg_date);
		setAanmelding_tandarts(data_gezondheidszorg.aanmelding_tandarts);
		setAanmelding_tandarts_date(data_gezondheidszorg.aanmelding_tandarts_date);
		setWsf(data_kinderen.wsf);
		setWsf_date(data_kinderen.wsf_date);
		setKinderbijslag(data_kinderen.kinderbijslag);
		setKinderbijslag_date(data_kinderen.kinderbijslag_date);
		setBusvg_schoolkinderen(data_kinderen.busvg_schoolkinderen);
		setBusvg_schoolkinderen_date(data_kinderen.busvg_schoolkinderen_date);
		setPeuteropvang(data_kinderen.peuteropvang);
		setPeuteropvang_date(data_kinderen.peuteropvang_date);
		setOverblijfkosten_basisschool(data_kinderen.overblijfkosten_basisschool);
		setOverblijfkosten_basisschool_date(
			data_kinderen.overblijfkosten_basisschool_date
		);
		setKinderopvang(data_kinderen.kinderopvang);
		setKinderopvang_date(data_kinderen.kinderopvang_date);
		setInburgersplicht(data_wetinburgering.inburgersplicht);
		setInburgersplicht_date(data_wetinburgering.inburgersplicht_date);
		setLening_duo(data_wetinburgering.lening_duo);
		setLening_duo_date(data_wetinburgering.lening_duo_date);
		setKeuze_inburgeringsaanbieder(
			data_wetinburgering.keuze_inburgeringsaanbieder
		);
		setKeuze_inburgeringsaanbieder_date(
			data_wetinburgering.keuze_inburgeringsaanbieder_date
		);
		setVergoeding_reiskosten(data_wetinburgering.vergoeding_reiskosten);
		setVergoeding_reiskosten_date(
			data_wetinburgering.vergoeding_reiskosten_date
		);
		setPvt(data_wetinburgering.pvt);
		setPvt_date(data_wetinburgering.pvt_date);
		setTaalhuis(data_wetinburgering.taalhuis);
		setTaalhuis_date(data_wetinburgering.taalhuis_date);
		setDiploma_waardering(data_wetinburgering.diploma_waardering);
		setDiploma_waardering_date(data_wetinburgering.diploma_waardering_date);
		setDocumenten(data_gezinshereniging.documenten);
		setDocumenten_date(data_gezinshereniging.documenten_date);
		setAanvraag_ind(data_gezinshereniging.aanvraag_ind);
		setAanvraag_ind_date(data_gezinshereniging.aanvraag_ind_date);
	}, [render]);

	const handleSubmit = (e) => {
		if (initiation === 1) {
			e.preventDefault();
			axios({
				method: "POST",
				url: weeksecondandthirdsave,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res.status);
					setInitiation(0);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			e.preventDefault();
			axios({
				method: "PUT",
				url: weeksecondandthridsupdate,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div>
			{" "}
			<button onClick={handleSubmit} className={styles.button}>
				<FontAwesomeIcon icon={faPaperPlane} className={styles.iconbutton} />{" "}
				Opslaan
			</button>
			<div>
				<div className={styles.columnar}>
					<div>
						<p className={styles.columnhead}>Gezondheidszorg</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Kennismaking huisarts</label>
							<select
								className={
									kennismaking_huisarts == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="kennismaking_huisarts"
								name="kennismaking_huisarts"
								value={kennismaking_huisarts}
								onChange={(e) => {
									kennismaking_huisarts === true
										? setKennismaking_huisarts(false)
										: setKennismaking_huisarts(true);
									setKennismaking_huisarts_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="kennismaking_huisarts_date"
								name="kennismaking_huisarts_date"
								value={kennismaking_huisarts_date}
								onChange={(e) => setKennismaking_huisarts_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Verlos- en kraamzorg</label>
							<select
								className={
									verlos_en_kraamzorg == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="verlos_en_kraamzorg"
								name="verlos_en_kraamzorg"
								value={verlos_en_kraamzorg}
								onChange={(e) => {
									verlos_en_kraamzorg === true
										? setVerlos_en_kraamzorg(false)
										: setVerlos_en_kraamzorg(true);
									setVerlos_en_kraamzorg_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="verlos_en_kraamzorg_date"
								name="verlos_en_kraamzorg_date"
								value={verlos_en_kraamzorg_date}
								onChange={(e) => setVerlos_en_kraamzorg_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Aanmelding tandarts</label>
							<select
								className={
									aanmelding_tandarts == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="aanmelding_tandarts"
								name="aanmelding_tandarts"
								value={aanmelding_tandarts}
								onChange={(e) => {
									aanmelding_tandarts === true
										? setAanmelding_tandarts(false)
										: setAanmelding_tandarts(true);
									setAanmelding_tandarts_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="aanmelding_tandarts_date"
								name="aanmelding_tandarts_date"
								value={aanmelding_tandarts_date}
								onChange={(e) => setAanmelding_tandarts_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
						<p className={styles.columnhead}>Gezinshereniging</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Documenten</label>
							<select
								className={
									documenten == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="documenten"
								name="documenten"
								value={documenten}
								onChange={(e) => {
									documenten === true
										? setDocumenten(false)
										: setDocumenten(true);
									setDocumenten_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="documenten_date"
								name="documenten_date"
								value={documenten_date}
								onChange={(e) => setDocumenten_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Aanvraag IND</label>
							<select
								className={
									aanvraag_ind == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="aanvraag_ind"
								name="aanvraag_ind"
								value={aanvraag_ind}
								onChange={(e) => {
									aanvraag_ind === true
										? setAanvraag_ind(false)
										: setAanvraag_ind(true);
									setAanvraag_ind_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="aanvraag_ind_date"
								name="aanvraag_ind_date"
								value={aanvraag_ind_date}
								onChange={(e) => setAanvraag_ind_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
					</div>
					<div>
						<p className={styles.columnhead}>Kinderen</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>WSF</label>
							<select
								className={
									wsf == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="wsf"
								name="wsf"
								value={wsf}
								onChange={(e) => {
									wsf === true ? setWsf(false) : setWsf(true);
									setWsf_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="wsf_date"
								name="wsf_date"
								value={wsf_date}
								onChange={(e) => setWsf_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Kinderbijslag</label>
							<select
								className={
									kinderbijslag == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="kinderbijslag"
								name="kinderbijslag"
								value={kinderbijslag}
								onChange={(e) => {
									kinderbijslag === true
										? setKinderbijslag(false)
										: setKinderbijslag(true);
									setKinderbijslag_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="kinderbijslag_date"
								name="kinderbijslag_date"
								value={kinderbijslag_date}
								onChange={(e) => setKinderbijslag_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Busvergoedering Kinderen</label>
							<select
								className={
									busvg_schoolkinderen == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="busvg_schoolkinderen"
								name="busvg_schoolkinderen"
								value={busvg_schoolkinderen}
								onChange={(e) => {
									busvg_schoolkinderen === true
										? setBusvg_schoolkinderen(false)
										: setBusvg_schoolkinderen(true);
									setBusvg_schoolkinderen_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="busvg_schoolkinderen_date"
								name="busvg_schoolkinderen_date"
								value={busvg_schoolkinderen_date}
								onChange={(e) => setBusvg_schoolkinderen_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Peuteropvang</label>
							<select
								className={
									peuteropvang == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="peuteropvang"
								name="peuteropvang"
								value={peuteropvang}
								onChange={(e) => {
									peuteropvang === true
										? setPeuteropvang(false)
										: setPeuteropvang(true);
									setPeuteropvang_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="peuteropvang_date"
								name="peuteropvang_date"
								value={peuteropvang_date}
								onChange={(e) => setPeuteropvang_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>
								Overblijf- en andere kosten school
							</label>
							<select
								className={
									overblijfkosten_basisschool == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="overblijfkosten_basisschool"
								name="overblijfkosten_basisschool"
								value={overblijfkosten_basisschool}
								onChange={(e) => {
									overblijfkosten_basisschool === true
										? setOverblijfkosten_basisschool(false)
										: setOverblijfkosten_basisschool(true);
									setOverblijfkosten_basisschool_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="overblijfkosten_basisschool_date"
								name="overblijfkosten_basisschool_date"
								value={overblijfkosten_basisschool_date}
								onChange={(e) =>
									setOverblijfkosten_basisschool_date(e.target.value)
								}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Kinderopvang</label>
							<select
								className={
									kinderopvang == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="kinderopvang"
								name="kinderopvang"
								value={kinderopvang}
								onChange={(e) => {
									kinderopvang === true
										? setKinderopvang(false)
										: setKinderopvang(true);
									setKinderopvang_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="kinderopvang_date"
								name="kinderopvang_date"
								value={kinderopvang_date}
								onChange={(e) => setKinderopvang_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
					</div>
					<div>
						<p className={styles.columnhead}>Wet inburgering</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Inburgeringplicht</label>
							<select
								className={
									inburgersplicht == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="inburgersplicht"
								name="inburgersplicht"
								value={inburgersplicht}
								onChange={(e) => {
									inburgersplicht === true
										? setInburgersplicht(false)
										: setInburgersplicht(true);
									setInburgersplicht_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="inburgersplicht_date"
								name="inburgersplicht_date"
								value={inburgersplicht_date}
								onChange={(e) => setInburgersplicht_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Lening DUO</label>
							<select
								className={
									lening_duo == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="lening_duo"
								name="lening_duo"
								value={lening_duo}
								onChange={(e) => {
									lening_duo === true
										? setLening_duo(false)
										: setLening_duo(true);
									setLening_duo_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="lening_duo_date"
								name="lening_duo_date"
								value={lening_duo_date}
								onChange={(e) => setLening_duo_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>
								Keuze aanbieder inburgering
							</label>
							<select
								className={
									keuze_inburgeringsaanbieder == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="keuze_inburgeringsaanbieder"
								name="keuze_inburgeringsaanbieder"
								value={keuze_inburgeringsaanbieder}
								onChange={(e) => {
									keuze_inburgeringsaanbieder === true
										? setKeuze_inburgeringsaanbieder(false)
										: setKeuze_inburgeringsaanbieder(true);
									setKeuze_inburgeringsaanbieder_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="keuze_inburgeringsaanbieder_date"
								name="keuze_inburgeringsaanbieder_date"
								value={keuze_inburgeringsaanbieder_date}
								onChange={(e) =>
									setKeuze_inburgeringsaanbieder_date(e.target.value)
								}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Vergoeding reiskosten</label>
							<select
								className={
									vergoeding_reiskosten == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="vergoeding_reiskosten"
								name="vergoeding_reiskosten"
								value={vergoeding_reiskosten}
								onChange={(e) => {
									vergoeding_reiskosten === true
										? setVergoeding_reiskosten(false)
										: setVergoeding_reiskosten(true);
									setVergoeding_reiskosten_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="vergoeding_reiskosten_date"
								name="vergoeding_reiskosten_date"
								value={vergoeding_reiskosten_date}
								onChange={(e) => setVergoeding_reiskosten_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>PVT</label>
							<select
								className={
									pvt == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="pvt"
								name="pvt"
								value={pvt}
								onChange={(e) => {
									pvt === true ? setPvt(false) : setPvt(true);
									setPvt_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="pvt_date"
								name="pvt_date"
								value={pvt_date}
								onChange={(e) => setPvt_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Taalhuis</label>
							<select
								className={
									taalhuis == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="taalhuis"
								name="taalhuis"
								value={taalhuis}
								onChange={(e) => {
									taalhuis === true ? setTaalhuis(false) : setTaalhuis(true);
									setTaalhuis_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="taalhuis_date"
								name="taalhuis_date"
								value={taalhuis_date}
								onChange={(e) => setTaalhuis_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Diplomawaardering</label>
							<select
								className={
									diploma_waardering == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="diploma_waardering"
								name="diploma_waardering"
								value={diploma_waardering}
								onChange={(e) => {
									diploma_waardering === true
										? setDiploma_waardering(false)
										: setDiploma_waardering(true);
									setDiploma_waardering_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="diploma_waardering_date"
								name="diploma_waardering_date"
								value={diploma_waardering_date}
								onChange={(e) => setDiploma_waardering_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
					</div>
				</div>
				<button onClick={handleSubmit} className={styles.button}>
					<FontAwesomeIcon icon={faPaperPlane} className={styles.iconbutton} />{" "}
					Opslaan
				</button>
			</div>
		</div>
	);
}

export default Na2cq3weken;
