import React, { useState, useEffect } from "react";
import Styles from "./Styles.jsx";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";

const styles = Styles;

function Regeldag(props) {
	const persoon_id = props.id;
	const [data, setData] = useState([]);
	const [initiation, setInitiation] = useState(1);

	const [aanvraag_uitkering_lo, setAanvraag_uitkering_lo] = useState(false);
	const [aanvraag_uitkering_lo_date, setAanvraag_uitkering_lo_date] =
		useState("1900-01-01");
	const [aanvraag_woonkostentoeslag, setAanvraag_woonkostentoeslag] =
		useState(false);
	const [aanvraag_woonkostentoeslag_date, setAanvraag_woonkostentoeslag_date] =
		useState("1900-01-01");
	const [aanvraag_bijzbijstand_kbnl, setAanvraag_bijzbijstand_kbnl] =
		useState(false);
	const [aanvraag_bijzbijstand_kbnl_date, setAanvraag_bijzbijstand_kbnl_date] =
		useState("1900-01-01");
	const [huurovereenkomst, setHuurovereenkomst] = useState(false);
	const [huurovereenkomst_date, setHuurovereenkomst_date] =
		useState("1900-01-01");
	const [aangifte_verhuizing_brp, setAangifte_verhuizing_brp] = useState(false);
	const [aangifte_verhuizing_brp_date, setAangifte_verhuizing_brp_date] =
		useState("1900-01-01");
	const [infopakket_gemeente, setInfopakket_gemeente] = useState(false);
	const [infopakket_gemeente_date, setInfopakket_gemeente_date] =
		useState("1900-01-01");
	const [digid_code, setDigid_code] = useState(false);
	const [digid_code_date, setDigid_code_date] = useState("1900-01-01");
	const [email_aanmaken, setEmail_aanmaken] = useState(false);
	const [email_aanmaken_date, setEmail_aanmaken_date] = useState("1900-01-01");
	const [medisch_dossier, setMedisch_dossier] = useState(false);
	const [medisch_dossier_date, setMedisch_dossier_date] =
		useState("1900-01-01");
	const [doorverwijzen_hulpverlening, setDoorverwijzen_hulpverlening] =
		useState(false);
	const [
		doorverwijzen_hulpverlening_date,
		setDoorverwijzen_hulpverlening_date,
	] = useState("1900-01-01");
	const [aanvragen_lening_kbnl, setAanvragen_lening_kbnl] = useState(false);
	const [aanvragen_lening_kbnl_date, setAanvragen_lening_kbnl_date] =
		useState("1900-01-01");
	const [aanvragen_fietsvergoeding, setAanvragen_fietsvergoeding] =
		useState(false);
	const [aanvragen_fietsvergoeding_date, setAanvragen_fietsvergoeding_date] =
		useState("1900-01-01");
	const [aanvragen_iit, setAanvragen_iit] = useState(false);
	const [aanvragen_iit_date, setAanvragen_iit_date] = useState("1900-01-01");
	const [bespreken_zorgverzekering, setBespreken_zorgverzekering] =
		useState(false);
	const [bespreken_zorgverzekering_date, setBespreken_zorgverzekering_date] =
		useState("1900-01-01");
	const [
		bespreken_advies_inrichtingslening,
		setBespreken_advies_inrichtingslening,
	] = useState(false);
	const [
		bespreken_advies_inrichtingslening_date,
		setBespreken_advies_inrichtingslening_date,
	] = useState("1900-01-01");

	const [render, setRender] = useState(0);
	const regeldag = "/api/regeldag/" + persoon_id;
	const regeldagsave = "/api/regeldagsave/" + persoon_id;
	const regeldagupdate = "/api/regeldagupdate/" + persoon_id;

	const savedata = {
		aanvraag_uitkering_lo: aanvraag_uitkering_lo,
		aanvraag_uitkering_lo_date: aanvraag_uitkering_lo_date,
		aanvraag_woonkostentoeslag: aanvraag_woonkostentoeslag,
		aanvraag_woonkostentoeslag_date: aanvraag_woonkostentoeslag_date,
		aanvraag_bijzbijstand_kbnl: aanvraag_bijzbijstand_kbnl,
		aanvraag_bijzbijstand_kbnl_date: aanvraag_bijzbijstand_kbnl_date,
		huurovereenkomst: huurovereenkomst,
		huurovereenkomst_date: huurovereenkomst_date,
		aangifte_verhuizing_brp: aangifte_verhuizing_brp,
		aangifte_verhuizing_brp_date: aangifte_verhuizing_brp_date,
		infopakket_gemeente: infopakket_gemeente,
		infopakket_gemeente_date: infopakket_gemeente_date,
		digid_code: digid_code,
		digid_code_date: digid_code_date,
		email_aanmaken: email_aanmaken,
		email_aanmaken_date: email_aanmaken_date,
		medisch_dossier: medisch_dossier,
		medisch_dossier_date: medisch_dossier_date,
		doorverwijzen_hulpverlening: doorverwijzen_hulpverlening,
		doorverwijzen_hulpverlening_date: doorverwijzen_hulpverlening_date,
		aanvragen_lening_kbnl: aanvragen_lening_kbnl,
		aanvragen_lening_kbnl_date: aanvragen_lening_kbnl_date,
		aanvragen_fietsvergoeding: aanvragen_fietsvergoeding,
		aanvragen_fietsvergoeding_date: aanvragen_fietsvergoeding_date,
		aanvragen_iit: aanvragen_iit,
		aanvragen_iit_date: aanvragen_iit_date,
		bespreken_zorgverzekering: bespreken_zorgverzekering,
		bespreken_zorgverzekering_date: bespreken_zorgverzekering_date,
		bespreken_advies_inrichtingslening: bespreken_advies_inrichtingslening,
		bespreken_advies_inrichtingslening_date:
			bespreken_advies_inrichtingslening_date,
		persoon_id: persoon_id,
	};

	useEffect(() => {
		axios({
			method: "GET",
			url: regeldag,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res.status);
				setData(res.data);
				setInitiation(0);
			})
			.then((res) => {
				setRender(true);
			})
			.catch((err) => {
				console.log(err);
				setInitiation(1);
			});
	}, []);

	useEffect(() => {
		setAanvraag_uitkering_lo(data.aanvraag_uitkering_lo);
		setAanvraag_uitkering_lo_date(data.aanvraag_uitkering_lo_date);
		setAanvraag_woonkostentoeslag(data.aanvraag_woonkostentoeslag);
		setAanvraag_woonkostentoeslag_date(data.aanvraag_woonkostentoeslag_date);
		setAanvraag_bijzbijstand_kbnl(data.aanvraag_bijzbijstand_kbnl);
		setAanvraag_bijzbijstand_kbnl_date(data.aanvraag_bijzbijstand_kbnl_date);
		setHuurovereenkomst(data.huurovereenkomst);
		setHuurovereenkomst_date(data.huurovereenkomst_date);
		setAangifte_verhuizing_brp(data.aangifte_verhuizing_brp);
		setAangifte_verhuizing_brp_date(data.aangifte_verhuizing_brp_date);
		setInfopakket_gemeente(data.infopakket_gemeente);
		setInfopakket_gemeente_date(data.infopakket_gemeente_date);
		setDigid_code(data.digid_code);
		setDigid_code_date(data.digid_code_date);
		setEmail_aanmaken(data.email_aanmaken);
		setEmail_aanmaken_date(data.email_aanmaken_date);
		setMedisch_dossier(data.medisch_dossier);
		setMedisch_dossier_date(data.medisch_dossier_date);
		setDoorverwijzen_hulpverlening(data.doorverwijzen_hulpverlening);
		setDoorverwijzen_hulpverlening_date(data.doorverwijzen_hulpverlening_date);
		setAanvragen_lening_kbnl(data.aanvragen_lening_kbnl);
		setAanvragen_lening_kbnl_date(data.aanvragen_lening_kbnl_date);
		setAanvragen_fietsvergoeding(data.aanvragen_fietsvergoeding);
		setAanvragen_fietsvergoeding_date(data.aanvragen_fietsvergoeding_date);
		setAanvragen_iit(data.aanvragen_iit);
		setAanvragen_iit_date(data.aanvragen_iit_date);
		setBespreken_zorgverzekering(data.bespreken_zorgverzekering);
		setBespreken_zorgverzekering_date(data.bespreken_zorgverzekering_date);
		setBespreken_advies_inrichtingslening(
			data.bespreken_advies_inrichtingslening
		);
		setBespreken_advies_inrichtingslening_date(
			data.bespreken_advies_inrichtingslening_date
		);
	}, [render]);

	const handleSubmit = (e) => {
		if (initiation === 1) {
			e.preventDefault();
			axios({
				method: "POST",
				url: regeldagsave,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res.status);
					setInitiation(0);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			e.preventDefault();
			axios({
				method: "PUT",
				url: regeldagupdate,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div>
			{" "}
			<button className={styles.button} onClick={handleSubmit}>
				<FontAwesomeIcon icon={faPaperPlane} /> Opslaan
			</button>{" "}
			<div>
				<div>
					<form className={styles.checklist}>
						<label className={styles.label}>Aanvraag uitkering l.o.</label>
						<select
							className={
								aanvraag_uitkering_lo === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="aanvraag_uitkering_lo"
							name="aanvraag_uitkering_lo"
							value={aanvraag_uitkering_lo}
							onChange={(e) => {
								aanvraag_uitkering_lo === true
									? setAanvraag_uitkering_lo(false)
									: setAanvraag_uitkering_lo(true);
								setAanvraag_uitkering_lo_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="aanvraag_uitkering_lo_date"
							name="aanvraag_uitkering_lo_date"
							value={aanvraag_uitkering_lo_date}
							onChange={(e) => setAanvraag_uitkering_lo_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Aanvraag woonkostentoeslag</label>
						<select
							className={
								aanvraag_woonkostentoeslag === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="aanvraag_woonkostentoeslag"
							name="aanvraag_woonkostentoeslag"
							value={aanvraag_woonkostentoeslag}
							onChange={(e) => {
								aanvraag_woonkostentoeslag === true
									? setAanvraag_woonkostentoeslag(false)
									: setAanvraag_woonkostentoeslag(true);
								setAanvraag_woonkostentoeslag_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="aanvraag_woonkostentoeslag_date"
							name="aanvraag_woonkostentoeslag_date"
							value={aanvraag_woonkostentoeslag_date}
							onChange={(e) =>
								setAanvraag_woonkostentoeslag_date(e.target.value)
							}
							className={styles.checkdate}
						/>
						<label className={styles.label}>
							Aanvraag bijzondere bijstand kbnl
						</label>
						<select
							className={
								aanvraag_bijzbijstand_kbnl === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="aanvraag_bijzbijstand_kbnl"
							name="aanvraag_bijzbijstand_kbnl"
							value={aanvraag_bijzbijstand_kbnl}
							onChange={(e) => {
								aanvraag_bijzbijstand_kbnl === true
									? setAanvraag_bijzbijstand_kbnl(false)
									: setAanvraag_bijzbijstand_kbnl(true);
								setAanvraag_bijzbijstand_kbnl_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="aanvraag_bijzbijstand_kbnl_date"
							name="aanvraag_bijzbijstand_kbnl_date"
							value={aanvraag_bijzbijstand_kbnl_date}
							onChange={(e) =>
								setAanvraag_bijzbijstand_kbnl_date(e.target.value)
							}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Huurovereenkomst</label>
						<select
							className={
								huurovereenkomst === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="huurovereenkomst"
							name="huurovereenkomst"
							value={huurovereenkomst}
							onChange={(e) => {
								huurovereenkomst === true
									? setHuurovereenkomst(false)
									: setHuurovereenkomst(true);
								setHuurovereenkomst_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="huurovereenkomst_date"
							name="huurovereenkomst_date"
							value={huurovereenkomst_date}
							onChange={(e) => setHuurovereenkomst_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Aangifte verhuizing BRP</label>
						<select
							className={
								aangifte_verhuizing_brp === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="aangifte_verhuizing_brp"
							name="aangifte_verhuizing_brp"
							value={aangifte_verhuizing_brp}
							onChange={(e) => {
								aangifte_verhuizing_brp === true
									? setAangifte_verhuizing_brp(false)
									: setAangifte_verhuizing_brp(true);
								setAangifte_verhuizing_brp_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="aangifte_verhuizing_brp_date"
							name="aangifte_verhuizing_brp_date"
							value={aangifte_verhuizing_brp_date}
							onChange={(e) => setAangifte_verhuizing_brp_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Infopakket Gemeente</label>
						<select
							className={
								infopakket_gemeente === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="infopakket_gemeente"
							name="infopakket_gemeente"
							value={infopakket_gemeente}
							onChange={(e) => {
								infopakket_gemeente === true
									? setInfopakket_gemeente(false)
									: setInfopakket_gemeente(true);
								setInfopakket_gemeente_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="infopakket_gemeente_date"
							name="infopakket_gemeente_date"
							value={infopakket_gemeente_date}
							onChange={(e) => setInfopakket_gemeente_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Digid Code</label>
						<select
							className={
								digid_code === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="digid_code"
							name="digid_code"
							value={digid_code}
							onChange={(e) => {
								digid_code === true
									? setDigid_code(false)
									: setDigid_code(true);
								setDigid_code_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="digid_code_date"
							name="digid_code_date"
							value={digid_code_date}
							onChange={(e) => setDigid_code_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Email aanmaken</label>
						<select
							className={
								email_aanmaken === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="email_aanmaken"
							name="email_aanmaken"
							value={email_aanmaken}
							onChange={(e) => {
								email_aanmaken === true
									? setEmail_aanmaken(false)
									: setEmail_aanmaken(true);
								setEmail_aanmaken_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="email_aanmaken_date"
							name="email_aanmaken_date"
							value={email_aanmaken_date}
							onChange={(e) => setEmail_aanmaken_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Medisch Dossier</label>
						<select
							className={
								medisch_dossier === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="medisch_dossier"
							name="medisch_dossier"
							value={medisch_dossier}
							onChange={(e) => {
								medisch_dossier === true
									? setMedisch_dossier(false)
									: setMedisch_dossier(true);
								setMedisch_dossier_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="medisch_dossier_date"
							name="medisch_dossier_date"
							value={medisch_dossier_date}
							onChange={(e) => setMedisch_dossier_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Doorverwijzen hulpverlening</label>
						<select
							className={
								doorverwijzen_hulpverlening === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="doorverwijzen_hulpverlening"
							name="doorverwijzen_hulpverlening"
							value={doorverwijzen_hulpverlening}
							onChange={(e) => {
								doorverwijzen_hulpverlening === true
									? setDoorverwijzen_hulpverlening(false)
									: setDoorverwijzen_hulpverlening(true);
								setDoorverwijzen_hulpverlening_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="doorverwijzen_hulpverlening_date"
							name="doorverwijzen_hulpverlening_date"
							value={doorverwijzen_hulpverlening_date}
							onChange={(e) =>
								setDoorverwijzen_hulpverlening_date(e.target.value)
							}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Aanvragen lening kbnl</label>
						<select
							className={
								aanvragen_lening_kbnl === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="aanvragen_lening_kbnl"
							name="aanvragen_lening_kbnl"
							value={aanvragen_lening_kbnl}
							onChange={(e) => {
								aanvragen_lening_kbnl === true
									? setAanvragen_lening_kbnl(false)
									: setAanvragen_lening_kbnl(true);
								setAanvragen_lening_kbnl_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="aanvragen_lening_kbnl_date"
							name="aanvragen_lening_kbnl_date"
							value={aanvragen_lening_kbnl_date}
							onChange={(e) => setAanvragen_lening_kbnl_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Aanvragen fietsvergoeding</label>
						<select
							className={
								aanvragen_fietsvergoeding === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="aanvragen_fietsvergoeding"
							name="aanvragen_fietsvergoeding"
							value={aanvragen_fietsvergoeding}
							onChange={(e) => {
								aanvragen_fietsvergoeding === true
									? setAanvragen_fietsvergoeding(false)
									: setAanvragen_fietsvergoeding(true);
								setAanvragen_fietsvergoeding_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="aanvragen_fietsvergoeding_date"
							name="aanvragen_fietsvergoeding_date"
							value={aanvragen_fietsvergoeding_date}
							onChange={(e) =>
								setAanvragen_fietsvergoeding_date(e.target.value)
							}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Aanvragen iit</label>
						<select
							className={
								aanvragen_iit === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="aanvragen_iit"
							name="aanvragen_iit"
							value={aanvragen_iit}
							onChange={(e) => {
								aanvragen_iit === true
									? setAanvragen_iit(false)
									: setAanvragen_iit(true);
								setAanvragen_iit_date(new Date().toISOString().slice(0, 10));
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="aanvragen_iit_date"
							name="aanvragen_iit_date"
							value={aanvragen_iit_date}
							onChange={(e) => setAanvragen_iit_date(e.target.value)}
							className={styles.checkdate}
						/>
						<label className={styles.label}>Bespreken zorgverzekering</label>
						<select
							className={
								bespreken_zorgverzekering === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="bespreken_zorgverzekering"
							name="bespreken_zorgverzekering"
							value={bespreken_zorgverzekering}
							onChange={(e) => {
								bespreken_zorgverzekering === true
									? setBespreken_zorgverzekering(false)
									: setBespreken_zorgverzekering(true);
								setBespreken_zorgverzekering_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="bespreken_zorgverzekering_date"
							name="bespreken_zorgverzekering_date"
							value={bespreken_zorgverzekering_date}
							onChange={(e) =>
								setBespreken_zorgverzekering_date(e.target.value)
							}
							className={styles.checkdate}
						/>
						<label className={styles.label}>
							Bespreken advies inrichtingslening
						</label>
						<select
							className={
								bespreken_advies_inrichtingslening === true
									? styles.checkselectcompl
									: styles.checkselectfirst
							}
							id="bespreken_advies_inrichtingslening"
							name="bespreken_advies_inrichtingslening"
							value={bespreken_advies_inrichtingslening}
							onChange={(e) => {
								bespreken_advies_inrichtingslening === true
									? setBespreken_advies_inrichtingslening(false)
									: setBespreken_advies_inrichtingslening(true);
								setBespreken_advies_inrichtingslening_date(
									new Date().toISOString().slice(0, 10)
								);
							}}
						>
							<option value={true}>Ja</option>
							<option value={false}>Nee</option>
						</select>
						<input
							type="date"
							id="bespreken_advies_inrichtingslening_date"
							name="bespreken_advies_inrichtingslening_date"
							value={bespreken_advies_inrichtingslening_date}
							onChange={(e) =>
								setBespreken_advies_inrichtingslening_date(e.target.value)
							}
							className={styles.checkdate}
						/>
					</form>{" "}
					<button className={styles.button} onClick={handleSubmit}>
						<FontAwesomeIcon icon={faPaperPlane} /> Opslaan
					</button>{" "}
				</div>
			</div>
		</div>
	);
}

export default Regeldag;
