import React, { useState, useEffect } from "react";
import Styles from "./Styles.jsx";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";

const styles = Styles;

function Vervolg(props) {
	const person_id = props.id;
	const [data_vervolg_inkomen, setData_vervolg_inkomen] = useState([]);
	const [data_vervolg_introductiebuurt, setData_vervolg_introductiebuurt] =
		useState([]);
	const [data_vervolg_gezondheidszorg, setData_vervolg_gezondheidszorg] =
		useState([]);
	const [data_vervolg_kinderen, setData_vervolg_kinderen] = useState([]);
	const [data_vervolg_inburgering, setData_vervolg_inburgering] = useState([]);
	const [data_vervolg_spreekuren, setData_vervolg_spreekuren] = useState([]);
	const [initiation, setInitiation] = useState(1);
	const [render, setRender] = useState(0);

	//states for vervolg inkomen
	const [uitleg_participatiewet, setUitleg_participatiewet] = useState(false);
	const [uitleg_participatiewet_date, setUitleg_participatiewet_date] =
		useState("1900-01-01");
	const [fin_maandoverzicht, setFin_maandoverzicht] = useState(false);
	const [fin_maandoverzicht_date, setFin_maandoverzicht_date] =
		useState("1900-01-01");
	const [internetbankieren, setInternetbankieren] = useState(false);
	const [internetbankieren_date, setInternetbankieren_date] =
		useState("1900-01-01");
	const [schuldhulp, setSchuldhulp] = useState(false);
	const [schuldhulp_date, setSchuldhulp_date] = useState("1900-01-01");

	//states vervolg introductie buurt
	const [sociaal_netwerk, setSociaal_netwerk] = useState(false);
	const [sociaal_netwerk_date, setSociaal_netwerk_date] =
		useState("1900-01-01");
	const [bibliotheek, setBibliotheek] = useState(false);
	const [bibliotheek_date, setBibliotheek_date] = useState("1900-01-01");
	const [spelotheek, setSpelotheek] = useState(false);
	const [spelotheek_date, setSpelotheek_date] = useState("1900-01-01");
	const [sport_ontspanning, setSport_ontspanning] = useState(false);
	const [sport_ontspanning_date, setSport_ontspanning_date] =
		useState("1900-01-01");
	const [lokale_minimaregelingen, setLokale_minimaregelingen] = useState(false);
	const [lokale_minimaregelingen_date, setLokale_minimaregelingen_date] =
		useState("1900-01-01");

	// status vervolg gezondheidszorg
	const [gezondheidszorg, setGezondheidszorg] = useState(false);
	const [gezondheidszorg_date, setGezondheidszorg_date] =
		useState("1900-01-01");
	const [loket_wijk, setLoket_wijk] = useState(false);
	const [loket_wijk_date, setLoket_wijk_date] = useState("1900-01-01");
	const [psychische_hulp, setPsychische_hulp] = useState(false);
	const [psychische_hulp_date, setPsychische_hulp_date] =
		useState("1900-01-01");

	// states vervolg kinderen
	const [onderwijssysteem, setOnderwijssysteem] = useState(false);
	const [onderwijssysteem_date, setOnderwijssysteem_date] =
		useState("1900-01-01");
	const [contact_ouders_school, setContact_ouders_school] = useState(false);
	const [contact_ouders_school_date, setContact_ouders_school_date] =
		useState("1900-01-01");
	const [opvoedondersteuning, setOpvoedondersteuning] = useState(false);
	const [opvoedondersteuning_date, setOpvoedondersteuning_date] =
		useState("1900-01-01");

	//states vervolg inburgering
	const [voortgang_inburgering, setVoortgang_inburgering] = useState(false);
	const [voortgang_inburgering_date, setVoortgang_inburgering_date] =
		useState("1900-01-01");
	const [ontheffing_inburgering, setOntheffing_inburgering] = useState(false);
	const [ontheffing_inburgering_date, setOntheffing_inburgering_date] =
		useState("1900-01-01");
	const [afstemming_rsd, setAfstemming_rsd] = useState(false);
	const [afstemming_rsd_date, setAfstemming_rsd_date] = useState("1900-01-01");
	const [kwijtschelding_duo_lening, setKwijtschelding_duo_lening] =
		useState(false);
	const [kwijtschelding_duo_lening_date, setKwijtschelding_duo_lening_date] =
		useState("1900-01-01");

	// states vervolg spreekuren
	const [vervolg_na_inburgering, setVervolg_na_inburgering] = useState(false);
	const [vervolg_na_inburgering_date, setVervolg_na_inburgering_date] =
		useState("1900-01-01");
	const [plannen_voortgangsgesprek, setPlannen_voortgangsgesprek] =
		useState(false);
	const [plannen_voortgangsgesprek_date, setPlannen_voortgangsgesprek_date] =
		useState("1900-01-01");
	const [overgang_spreekuur, setOvergang_spreekuur] = useState(false);
	const [overgang_spreekuur_date, setOvergang_spreekuur_date] =
		useState("1900-01-01");
	const [exitgesprek, setExitgesprek] = useState(false);
	const [exitgesprek_date, setExitgesprek_date] = useState("1900-01-01");

	const savedata = {
		uitleg_participatiewet: uitleg_participatiewet,
		uitleg_participatiewet_date: uitleg_participatiewet_date,
		fin_maandoverzicht: fin_maandoverzicht,
		fin_maandoverzicht_date: fin_maandoverzicht_date,
		internetbankieren: internetbankieren,
		internetbankieren_date: internetbankieren_date,
		schuldhulp: schuldhulp,
		schuldhulp_date: schuldhulp_date,
		sociaal_netwerk: sociaal_netwerk,
		sociaal_netwerk_date: sociaal_netwerk_date,
		bibliotheek: bibliotheek,
		bibliotheek_date: bibliotheek_date,
		spelotheek: spelotheek,
		spelotheek_date: spelotheek_date,
		sport_ontspanning: sport_ontspanning,
		sport_ontspanning_date: sport_ontspanning_date,
		lokale_minimaregelingen: lokale_minimaregelingen,
		lokale_minimaregelingen_date: lokale_minimaregelingen_date,
		gezondheidszorg: gezondheidszorg,
		gezondheidszorg_date: gezondheidszorg_date,
		loket_wijk: loket_wijk,
		loket_wijk_date: loket_wijk_date,
		psychische_hulp: psychische_hulp,
		psychische_hulp_date: psychische_hulp_date,
		onderwijssysteem: onderwijssysteem,
		onderwijssysteem_date: onderwijssysteem_date,
		contact_ouders_school: contact_ouders_school,
		contact_ouders_school_date: contact_ouders_school_date,
		opvoedondersteuning: opvoedondersteuning,
		opvoedondersteuning_date: opvoedondersteuning_date,
		voortgang_inburgering: voortgang_inburgering,
		voortgang_inburgering_date: voortgang_inburgering_date,
		ontheffing_inburgering: ontheffing_inburgering,
		ontheffing_inburgering_date: ontheffing_inburgering_date,
		afstemming_rsd: afstemming_rsd,
		afstemming_rsd_date: afstemming_rsd_date,
		kwijtschelding_duo_lening: kwijtschelding_duo_lening,
		kwijtschelding_duo_lening_date: kwijtschelding_duo_lening_date,
		vervolg_na_inburgering: vervolg_na_inburgering,
		vervolg_na_inburgering_date: vervolg_na_inburgering_date,
		plannen_voortgangsgesprek: plannen_voortgangsgesprek,
		plannen_voortgangsgesprek_date: plannen_voortgangsgesprek_date,
		overgang_spreekuur: overgang_spreekuur,
		overgang_spreekuur_date: overgang_spreekuur_date,
		exitgesprek: exitgesprek,
		exitgesprek_date: exitgesprek_date,
	};

	const vervolg = "/api/vervolg/" + person_id;
	const vervolg_save = "/api/vervolg_save/" + person_id;
	const vervolg_update = "/api/vervolg_update/" + person_id;

	useEffect(() => {
		axios({
			method: "GET",
			url: vervolg,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res.status);
				setData_vervolg_inkomen(res.data.vervolg_inkomen);
				setData_vervolg_introductiebuurt(res.data.vervolg_introductiebuurt);
				setData_vervolg_kinderen(res.data.vervolg_kinderen);
				setData_vervolg_gezondheidszorg(res.data.vervolg_gezondheidszorg);
				setData_vervolg_inburgering(res.data.vervolg_inburgering);
				setData_vervolg_spreekuren(res.data.vervolg_spreekuren);

				console.log(res.data);

				setInitiation(0);
			})
			.then((res) => {
				setRender(true);
			})
			.catch((err) => {
				console.log(err);
				setInitiation(1);
			});
	}, []);

	useEffect(() => {
		setUitleg_participatiewet(data_vervolg_inkomen.uitleg_participatiewet);
		setUitleg_participatiewet_date(
			data_vervolg_gezondheidszorg.uitleg_participatiewet_date
		);
		setFin_maandoverzicht(data_vervolg_inkomen.fin_maandoverzicht);
		setFin_maandoverzicht_date(data_vervolg_inkomen.fin_maandoverzicht_date);
		setInternetbankieren(data_vervolg_inkomen.internetbankieren);
		setInternetbankieren_date(data_vervolg_inkomen.internetbankieren_date);
		setSchuldhulp(data_vervolg_inkomen.schuldhulp);
		setSchuldhulp_date(data_vervolg_inkomen.schuldhulp_date);
		setSociaal_netwerk(data_vervolg_introductiebuurt.sociaal_netwerk);
		setSociaal_netwerk_date(data_vervolg_introductiebuurt.sociaal_netwerk_date);
		setBibliotheek(data_vervolg_introductiebuurt.bibliotheek);
		setBibliotheek_date(data_vervolg_introductiebuurt.bibliotheek_date);
		setSpelotheek(data_vervolg_introductiebuurt.spelotheek);
		setSpelotheek_date(data_vervolg_introductiebuurt.spelotheek_date);
		setSport_ontspanning(data_vervolg_introductiebuurt.sport_ontspanning);
		setSport_ontspanning_date(
			data_vervolg_introductiebuurt.sport_ontspanning_date
		);
		setLokale_minimaregelingen(
			data_vervolg_introductiebuurt.lokale_minimaregelingen
		);
		setLokale_minimaregelingen_date(
			data_vervolg_introductiebuurt.lokale_minimaregelingen_date
		);
		setGezondheidszorg(data_vervolg_gezondheidszorg.gezondheidszorg);
		setGezondheidszorg_date(data_vervolg_gezondheidszorg.gezondheidszorg_date);
		setLoket_wijk(data_vervolg_gezondheidszorg.loket_wijk);
		setLoket_wijk_date(data_vervolg_gezondheidszorg.loket_wijk_date);
		setPsychische_hulp(data_vervolg_gezondheidszorg.psychische_hulp);
		setPsychische_hulp_date(data_vervolg_gezondheidszorg.psychische_hulp_date);
		setOnderwijssysteem(data_vervolg_kinderen.onderwijssysteem);
		setOnderwijssysteem_date(data_vervolg_kinderen.onderwijssysteem_date);
		setContact_ouders_school(data_vervolg_kinderen.contact_ouders_school);
		setContact_ouders_school_date(
			data_vervolg_kinderen.contact_ouders_school_date
		);
		setOpvoedondersteuning(data_vervolg_kinderen.opvoedondersteuning);
		setOpvoedondersteuning_date(data_vervolg_kinderen.opvoedondersteuning_date);
		setVoortgang_inburgering(data_vervolg_inburgering.voortgang_inburgering);
		setVoortgang_inburgering_date(
			data_vervolg_inburgering.voortgang_inburgering_date
		);
		setOntheffing_inburgering(data_vervolg_inburgering.ontheffing_inburgering);
		setOntheffing_inburgering_date(
			data_vervolg_inburgering.ontheffing_inburgering_date
		);
		setAfstemming_rsd(data_vervolg_inburgering.afstemming_rsd);
		setAfstemming_rsd_date(data_vervolg_inburgering.afstemming_rsd_date);
		setKwijtschelding_duo_lening(
			data_vervolg_inburgering.kwijtschelding_duo_lening
		);
		setKwijtschelding_duo_lening_date(
			data_vervolg_inburgering.kwijtschelding_duo_lening_date
		);
		setVervolg_na_inburgering(data_vervolg_spreekuren.vervolg_na_inburgering);
		setVervolg_na_inburgering_date(
			data_vervolg_spreekuren.vervolg_na_inburgering_date
		);
		setPlannen_voortgangsgesprek(
			data_vervolg_spreekuren.plannen_voortgangsgesprek
		);
		setPlannen_voortgangsgesprek_date(
			data_vervolg_spreekuren.plannen_voortgangsgesprek_date
		);
		setOvergang_spreekuur(data_vervolg_spreekuren.overgang_spreekuur);
		setOvergang_spreekuur_date(data_vervolg_spreekuren.overgang_spreekuur_date);
		setExitgesprek(data_vervolg_spreekuren.exitgesprek);
		setExitgesprek_date(data_vervolg_spreekuren.exitgesprek_date);
	}, [render]);

	const handleSubmit = (e) => {
		if (initiation === 1) {
			e.preventDefault();
			axios({
				method: "POST",
				url: vervolg_save,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res.status);
					setInitiation(0);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			e.preventDefault();
			axios({
				method: "PUT",
				url: vervolg_update,
				headers: { Authorization: "Bearer " + props.token },
				data: savedata,
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div>
			{" "}
			<button onClick={handleSubmit} className={styles.button}>
				<FontAwesomeIcon icon={faPaperPlane} className={styles.iconbutton} />{" "}
				Opslaan
			</button>
			<div>
				<div className={styles.columnar}>
					<div>
						<p className={styles.columnhead}>Inkomen</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Uitleg participatiewet</label>
							<select
								className={
									uitleg_participatiewet == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="uitleg_participatiewet"
								name="uitleg_participatiewet"
								value={uitleg_participatiewet}
								onChange={(e) => {
									uitleg_participatiewet === true
										? setUitleg_participatiewet(false)
										: setUitleg_participatiewet(true);
									setUitleg_participatiewet_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="uitleg_participatiewet_date"
								name="uitleg_participatiewet_date"
								value={uitleg_participatiewet_date}
								onChange={(e) => setUitleg_participatiewet_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Financieel maandoverzicht</label>
							<select
								className={
									fin_maandoverzicht == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="fin_maandoverzicht"
								name="fin_maandoverzicht"
								value={fin_maandoverzicht}
								onChange={(e) => {
									fin_maandoverzicht === true
										? setFin_maandoverzicht(false)
										: setFin_maandoverzicht(true);
									setFin_maandoverzicht_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="fin_maandoverzicht_date"
								name="fin_maandoverzicht_date"
								value={fin_maandoverzicht_date}
								onChange={(e) => setFin_maandoverzicht_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Internetbankieren</label>
							<select
								className={
									internetbankieren == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="internetbankieren"
								name="internetbankieren"
								value={internetbankieren}
								onChange={(e) => {
									internetbankieren === true
										? setInternetbankieren(false)
										: setInternetbankieren(true);
									setInternetbankieren_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="internetbankieren_date"
								name="internetbankieren_date"
								value={internetbankieren_date}
								onChange={(e) => setInternetbankieren_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Schuldhulp</label>
							<select
								className={
									schuldhulp == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="schuldhulp"
								name="schuldhulp"
								value={schuldhulp}
								onChange={(e) => {
									schuldhulp === true
										? setSchuldhulp(false)
										: setSchuldhulp(true);
									setSchuldhulp_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="schuldhulp_date"
								name="schuldhulp_date"
								value={schuldhulp_date}
								onChange={(e) => setSchuldhulp_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
						<p className={styles.columnhead}>Introductie buurt</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Sociaal Netwerk</label>
							<select
								className={
									sociaal_netwerk == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="sociaal_netwerk"
								name="sociaal_netwerk"
								value={sociaal_netwerk}
								onChange={(e) => {
									sociaal_netwerk === true
										? setSociaal_netwerk(false)
										: setSociaal_netwerk(true);
									setSociaal_netwerk_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="sociaal_netwerk_date"
								name="sociaal_netwerk_date"
								value={sociaal_netwerk_date}
								onChange={(e) => setSociaal_netwerk_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Bibliotheek</label>
							<select
								className={
									bibliotheek == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="bibliotheek"
								name="bibliotheek"
								value={bibliotheek}
								onChange={(e) => {
									bibliotheek === true
										? setBibliotheek(false)
										: setBibliotheek(true);
									setBibliotheek_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="bibliotheek_date"
								name="bibliotheek_date"
								value={bibliotheek_date}
								onChange={(e) => setBibliotheek_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Spelotheek</label>
							<select
								className={
									spelotheek == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="spelotheek"
								name="spelotheek"
								value={spelotheek}
								onChange={(e) => {
									spelotheek === true
										? setSpelotheek(false)
										: setSpelotheek(true);
									setSpelotheek_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="spelotheek_date"
								name="spelotheek_date"
								value={spelotheek_date}
								onChange={(e) => setSpelotheek_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Sport en ontspanning</label>
							<select
								className={
									sport_ontspanning == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="sport_ontspanning"
								name="sport_ontspanning"
								value={sport_ontspanning}
								onChange={(e) => {
									sport_ontspanning === true
										? setSport_ontspanning(false)
										: setSport_ontspanning(true);
									setSport_ontspanning_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="sport_ontspanning_date"
								name="sport_ontspanning_date"
								value={sport_ontspanning_date}
								onChange={(e) => setSport_ontspanning_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Lokale minimaregelingen</label>
							<select
								className={
									lokale_minimaregelingen == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="lokale_minimaregelingen"
								name="lokale_minimaregelingen"
								value={lokale_minimaregelingen}
								onChange={(e) => {
									lokale_minimaregelingen === true
										? setLokale_minimaregelingen(false)
										: setLokale_minimaregelingen(true);
									setLokale_minimaregelingen_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="lokale_minimaregelingen_date"
								name="lokale_minimaregelingen_date"
								value={lokale_minimaregelingen_date}
								onChange={(e) =>
									setLokale_minimaregelingen_date(e.target.value)
								}
								className={styles.checkdate}
							/>
						</form>
					</div>
					<div>
						<p className={styles.columnhead}>Gezondheidszorg</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Gezondheidszorg</label>
							<select
								className={
									gezondheidszorg == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="gezondheidszorg"
								name="gezondheidszorg"
								value={gezondheidszorg}
								onChange={(e) => {
									gezondheidszorg === true
										? setGezondheidszorg(false)
										: setGezondheidszorg(true);
									setGezondheidszorg_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="gezondheidszorg_date"
								name="gezondheidszorg_date"
								value={gezondheidszorg_date}
								onChange={(e) => setGezondheidszorg_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Loket Wijk</label>
							<select
								className={
									loket_wijk == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="loket_wijk"
								name="loket_wijk"
								value={loket_wijk}
								onChange={(e) => {
									loket_wijk === true
										? setLoket_wijk(false)
										: setLoket_wijk(true);
									setLoket_wijk_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="loket_wijk_date"
								name="loket_wijk_date"
								value={loket_wijk_date}
								onChange={(e) => setLoket_wijk_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Psychische hulp</label>
							<select
								className={
									psychische_hulp == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="psychische_hulp"
								name="psychische_hulp"
								value={psychische_hulp}
								onChange={(e) => {
									psychische_hulp === true
										? setPsychische_hulp(false)
										: setPsychische_hulp(true);
									setPsychische_hulp_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="psychische_hulp_date"
								name="psychische_hulp_date"
								value={psychische_hulp_date}
								onChange={(e) => setPsychische_hulp_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
						<p className={styles.columnhead}>Kinderen</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Onderwijssysteem</label>
							<select
								className={
									onderwijssysteem == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="onderwijssysteem"
								name="onderwijssysteem"
								value={onderwijssysteem}
								onChange={(e) => {
									onderwijssysteem === true
										? setOnderwijssysteem(false)
										: setOnderwijssysteem(true);
									setOnderwijssysteem_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="onderwijssysteem_date"
								name="onderwijssysteem_date"
								value={onderwijssysteem_date}
								onChange={(e) => setOnderwijssysteem_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Contact ouders met school</label>
							<select
								className={
									contact_ouders_school == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="contact_ouders_school"
								name="contact_ouders_school"
								value={contact_ouders_school}
								onChange={(e) => {
									contact_ouders_school === true
										? setContact_ouders_school(false)
										: setContact_ouders_school(true);
									setContact_ouders_school_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="contact_ouders_school_date"
								name="contact_ouders_school_date"
								value={contact_ouders_school_date}
								onChange={(e) => setContact_ouders_school_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Opvoedondersteuning</label>
							<select
								className={
									opvoedondersteuning == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="opvoedondersteuning"
								name="opvoedondersteuning"
								value={opvoedondersteuning}
								onChange={(e) => {
									opvoedondersteuning === true
										? setOpvoedondersteuning(false)
										: setOpvoedondersteuning(true);
									setOpvoedondersteuning_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="opvoedondersteuning_date"
								name="opvoedondersteuning_date"
								value={opvoedondersteuning_date}
								onChange={(e) => setOpvoedondersteuning_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
					</div>
					<div>
						<p className={styles.columnhead}>Inburgering</p>

						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Voortgang inburgering</label>
							<select
								className={
									voortgang_inburgering == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="voortgang_inburgering"
								name="voortgang_inburgering"
								value={voortgang_inburgering}
								onChange={(e) => {
									voortgang_inburgering === true
										? setVoortgang_inburgering(false)
										: setVoortgang_inburgering(true);
									setVoortgang_inburgering_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="voortgang_inburgering_date"
								name="voortgang_inburgering_date"
								value={voortgang_inburgering_date}
								onChange={(e) => setVoortgang_inburgering_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Ontheffing inburgering</label>
							<select
								className={
									ontheffing_inburgering == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="ontheffing_inburgering"
								name="ontheffing_inburgering"
								value={ontheffing_inburgering}
								onChange={(e) => {
									ontheffing_inburgering === true
										? setOntheffing_inburgering(false)
										: setOntheffing_inburgering(true);
									setOntheffing_inburgering_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="ontheffing_inburgering_date"
								name="ontheffing_inburgering_date"
								value={ontheffing_inburgering_date}
								onChange={(e) => setOntheffing_inburgering_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Afstemming RSD</label>
							<select
								className={
									afstemming_rsd == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="afstemming_rsd"
								name="afstemming_rsd"
								value={afstemming_rsd}
								onChange={(e) => {
									afstemming_rsd === true
										? setAfstemming_rsd(false)
										: setAfstemming_rsd(true);
									setAfstemming_rsd_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="afstemming_rsd_date"
								name="afstemming_rsd_date"
								value={afstemming_rsd_date}
								onChange={(e) => setAfstemming_rsd_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Kwijtschelding DUO lening</label>
							<select
								className={
									kwijtschelding_duo_lening == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="kwijtschelding_duo_lening"
								name="kwijtschelding_duo_lening"
								value={kwijtschelding_duo_lening}
								onChange={(e) => {
									kwijtschelding_duo_lening === true
										? setKwijtschelding_duo_lening(false)
										: setKwijtschelding_duo_lening(true);
									setKwijtschelding_duo_lening_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="kwijtschelding_duo_lening_date"
								name="kwijtschelding_duo_lening_date"
								value={kwijtschelding_duo_lening_date}
								onChange={(e) =>
									setKwijtschelding_duo_lening_date(e.target.value)
								}
								className={styles.checkdate}
							/>
						</form>
						<p className={styles.columnhead}>Spreekuren</p>
						<form onSubmit={handleSubmit} className={styles.checklistmulti}>
							<label className={styles.label}>Vervolg na inburgering</label>
							<select
								className={
									vervolg_na_inburgering == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="vervolg_na_inburgering"
								name="vervolg_na_inburgering"
								value={vervolg_na_inburgering}
								onChange={(e) => {
									vervolg_na_inburgering === true
										? setVervolg_na_inburgering(false)
										: setVervolg_na_inburgering(true);
									setVervolg_na_inburgering_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="vervolg_na_inburgering_date"
								name="vervolg_na_inburgering_date"
								value={vervolg_na_inburgering_date}
								onChange={(e) => setVervolg_na_inburgering_date(e.target.value)}
								className={styles.checkdate}
							/>

							<label className={styles.label}>Plannen voortgangsgesprek</label>
							<select
								className={
									plannen_voortgangsgesprek == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="plannen_voortgangsgesprek"
								name="plannen_voortgangsgesprek"
								value={plannen_voortgangsgesprek}
								onChange={(e) => {
									plannen_voortgangsgesprek === true
										? setPlannen_voortgangsgesprek(false)
										: setPlannen_voortgangsgesprek(true);
									setPlannen_voortgangsgesprek_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="plannen_voortgangsgesprek_date"
								name="plannen_voortgangsgesprek_date"
								value={plannen_voortgangsgesprek_date}
								onChange={(e) =>
									setPlannen_voortgangsgesprek_date(e.target.value)
								}
								className={styles.checkdate}
							/>
							<label className={styles.label}>Overgang spreekuur</label>
							<select
								className={
									overgang_spreekuur == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="overgang_spreekuur"
								name="overgang_spreekuur"
								value={overgang_spreekuur}
								onChange={(e) => {
									overgang_spreekuur === true
										? setOvergang_spreekuur(false)
										: setOvergang_spreekuur(true);
									setOvergang_spreekuur_date(
										new Date().toISOString().slice(0, 10)
									);
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="overgang_spreekuur_date"
								name="overgang_spreekuur_date"
								value={overgang_spreekuur_date}
								onChange={(e) => setOvergang_spreekuur_date(e.target.value)}
								className={styles.checkdate}
							/>
							<label className={styles.label}>Exit gesprek</label>
							<select
								className={
									exitgesprek == true
										? styles.checkselectcompl
										: styles.checkselectfirst
								}
								id="exitgesprek"
								name="exitgesprek"
								value={exitgesprek}
								onChange={(e) => {
									exitgesprek === true
										? setExitgesprek(false)
										: setExitgesprek(true);
									setExitgesprek_date(new Date().toISOString().slice(0, 10));
								}}
							>
								<option value="true">Ja</option>
								<option value="false">Nee</option>
							</select>
							<input
								type="date"
								id="exitgesprek_date"
								name="exitgesprek_date"
								value={exitgesprek_date}
								onChange={(e) => setExitgesprek_date(e.target.value)}
								className={styles.checkdate}
							/>
						</form>
					</div>
				</div>
				<button onClick={handleSubmit} className={styles.button}>
					<FontAwesomeIcon icon={faPaperPlane} className={styles.iconbutton} />{" "}
					Opslaan
				</button>
			</div>
		</div>
	);
}

export default Vervolg;
