import axios from "axios";
import version from "../Utilities/version";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalkingArrowRight } from "@fortawesome/pro-duotone-svg-icons";

function Header(props) {
	const styles = {
		headersize:
			"h-[80px] bg-cyan-800 text-white flex justify-between items-center px-5",
		logo: " h-[50pt]",
		icon: "text-[14pt] mr-2",
		button:
			"text-[14pt] border bg-green-600 hover:bg-green-700 w-36 h-10 text-white rounded-lg",
		nli: "text-[14pt] mt-1 text-green-600 font-bold",
		version: "text-[8pt] mt-1 text-green-100 font-bold",
	};

	const logo = require("../Assets/Pictures/Vwwbd logo.jpg");

	function logMeOut() {
		axios({
			method: "POST",
			url: "/api/logoutreact",
		})
			.then((response) => {
				props.token();
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			});
	}

	return (
		<header className={styles.headersize}>
			<div>
				<img className={styles.logo} alt="logo" src={logo} />
			</div>
			{props.active === null ? (
				<h1 className={styles.nli}>Not logged in, add your credentials</h1>
			) : (
				<button className={styles.button} onClick={logMeOut}>
					<FontAwesomeIcon
						className={styles.icon}
						icon={faPersonWalkingArrowRight}
					/>
					Uitloggen
				</button>
			)}
			<ul>
				<li className={styles.version}>version: {version.frontend}</li>
			</ul>
		</header>
	);
}

export default Header;
